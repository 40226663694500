import {
  DedicatedRoadsLegendIcon,
  ExclusionZonesLegendIcon,
  MainRoutesLegendIcon,
  OppositesLegendIcon,
  SharedRoadsLegendIcon,
  TParkingNetwork,
  TParkingType,
  TemporaryFacilitiesLegendIcon,
  TrafficCalmingZonesLegendIcon,
  parkingNetworks,
  parkingTypes,
} from '@geovelo-frontends/commons';
import { ExpandLess } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { Fragment, SyntheticEvent, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { environment } from '../environment';

import { IControl, Map } from '!maplibre-gl';

type TItemKeys =
  | 'dedicatedRoads'
  | 'opposites'
  | 'sharedRoads'
  | 'trafficCalmingZones'
  | 'temporaryFacilities'
  | 'exclusionZones'
  | 'mainRoutes';

const items: Array<{
  key: TItemKeys;
  Icon: (props: SvgIconProps) => JSX.Element;
  labelKey: string;
}> = [
  {
    key: 'dedicatedRoads',
    Icon: DedicatedRoadsLegendIcon,
    labelKey: 'commons.facilities_legend.dedicated_roads',
  },
  { key: 'opposites', Icon: OppositesLegendIcon, labelKey: 'commons.facilities_legend.opposites' },
  {
    key: 'sharedRoads',
    Icon: SharedRoadsLegendIcon,
    labelKey: 'commons.facilities_legend.shared_roads',
  },
  {
    key: 'trafficCalmingZones',
    Icon: TrafficCalmingZonesLegendIcon,
    labelKey: 'commons.facilities_legend.traffic_calming_zones',
  },
  {
    key: 'temporaryFacilities',
    Icon: TemporaryFacilitiesLegendIcon,
    labelKey: 'commons.facilities_legend.temporary_facilities',
  },
  {
    key: 'exclusionZones',
    Icon: ExclusionZonesLegendIcon,
    labelKey: 'commons.facilities_legend.exclusion_zones',
  },
  {
    key: 'mainRoutes',
    Icon: MainRoutesLegendIcon,
    labelKey: 'commons.facilities_legend.main_routes',
  },
];

const parkingTypeKeys: TParkingType[] = ['simple', 'supervised', 'sheltered', 'locked'];

const parkingNetworkKeys: TParkingNetwork[] = ['bicibox', 'veligo', 'velocity', 'veloceo'];

export class LegendControl implements IControl {
  private map?: Map;
  private container?: HTMLDivElement;

  constructor(private readonly id: string) {}

  onAdd(_map: Map): HTMLDivElement {
    this.map = _map;
    this.container = document.createElement('div');
    this.container.className = 'maplibregl-ctrl';

    const content = document.createElement('div');
    content.id = this.id;
    this.container.appendChild(content);

    return this.container;
  }

  init(theme: Theme): void {
    const ele = document.getElementById(this.id);
    if (!ele) return;

    const container = createRoot(ele);
    container.render(<LegendControlComponent theme={theme} />);
  }

  onRemove(): void {
    this.container?.parentNode?.removeChild(this.container);
    this.map = undefined;
  }
}

function LegendControlComponent({ theme }: { theme: Theme }): JSX.Element {
  const [expanded, expand] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <StyledAccordion
        expanded={expanded}
        onChange={(_: SyntheticEvent, _expanded: boolean) => expand(_expanded)}
      >
        <StyledAccordionSummary expandIcon={<ExpandLess />}>
          <Typography variant="caption">
            <Trans i18nKey="commons.legend" />
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledList dense>
            {items
              .filter(({ key }) => environment.enableTmpFacilities || key !== 'temporaryFacilities')
              .map(({ key, Icon, labelKey }, index) => (
                <Fragment key={key}>
                  {index > 0 && <Divider />}
                  <ListItem>
                    <StyledListItemIcon>
                      <Icon />
                    </StyledListItemIcon>
                    <StyledListItemText
                      primary={
                        <Typography variant="caption">
                          <Trans i18nKey={labelKey} />
                        </Typography>
                      }
                    />
                  </ListItem>
                </Fragment>
              ))}
          </StyledList>
          <StyledList dense>
            {parkingTypeKeys.map((key, index) => {
              const { icon, labelKey } = parkingTypes[key];

              return (
                <Fragment key={key}>
                  {index > 0 && <Divider />}
                  <ListItem>
                    <StyledListItemIcon>
                      <StyledParkingIcon
                        className={
                          key === 'supervised' ? 'supervised' : key === 'locked' ? 'locked' : ''
                        }
                        src={icon}
                      />
                    </StyledListItemIcon>
                    <StyledListItemText
                      primary={
                        <Typography variant="caption">
                          <Trans i18nKey={labelKey} />
                        </Typography>
                      }
                    />
                  </ListItem>
                </Fragment>
              );
            })}
            {parkingNetworkKeys.map((key) => {
              const { icon, title } = parkingNetworks[key];

              return (
                <Fragment key={key}>
                  <Divider />
                  <ListItem>
                    <StyledListItemIcon>
                      <StyledParkingIcon className="network" src={icon} />
                    </StyledListItemIcon>
                    <StyledListItemText
                      primary={<Typography variant="caption">{title}</Typography>}
                    />
                  </ListItem>
                </Fragment>
              );
            })}
          </StyledList>
        </StyledAccordionDetails>
      </StyledAccordion>
    </ThemeProvider>
  );
}

const StyledAccordion = styled(Accordion)`
  width: 300px;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &&.Mui-expanded {
    min-height: 48px;

    .MuiAccordionSummary-content {
      margin: 12px 0;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
`;

const StyledList = styled(List)`
  && {
    padding: 0;
    width: 50%;

    li {
      padding: 2px 0;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 36px;
  }
`;

const StyledListItemText = styled(ListItemText)`
  && {
    margin: 0;
  }
`;

const StyledParkingIcon = styled.img`
  border-radius: 50%;
  margin: 2px;
  height: 20px;
  width: 20px;

  &:not(.network) {
    background-color: #6171ff;
    height: 16px;
    margin: 4px;
    width: 16px;
  }

  &.supervised,
  &.locked {
    background-color: #7b7a9e;
  }
`;
