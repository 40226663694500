import {
  NewReviewFormDialog,
  Report,
  ReportService,
  RouteReport,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Add } from '@mui/icons-material';
import { Fab, Tooltip } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import ContributionFormDialog from '../../components/contribution-form-dialog';

import Details from './details';
import ReportsList from './list';

export function Contributions(): JSX.Element {
  const {
    map: { current: map },
    user: { current: currentUser },
    zone: { current: currentZone },
    report: { types, selectedReport, selectedPeriod },
    routing: { contributionId },
    actions: { openSignInDialog, setReports, setSelectedReport },
  } = useContext(AppContext);
  const [reviewDialogOpen, openReviewDialog] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState<{ [key: number]: boolean }>({});
  const [contributeFormDialogOpen, openContributeFormDialog] = useState(false);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const selectedTypesCount = useRef<number>(0);

  useEffect(() => {
    if (types) {
      setSelectedTypes(
        types.reduce<{ [key: number]: boolean }>((res, type) => {
          res[type.id] = true;
          selectedTypesCount.current++;
          return res;
        }, {}),
      );
    }
  }, [types]);

  useEffect(() => {
    setReports(undefined);
    if (contributionId === null) getContributions();
  }, [currentZone, selectedTypes, selectedPeriod, contributionId]);

  useEffect(() => {
    if (contributionId) getContribution();
    else setSelectedReport(null);
  }, [contributionId]);

  function selectedTypeChanged(id: number, checked: boolean): void {
    cancelPromises();
    setSelectedTypes({ ...selectedTypes, [id]: checked });
    checked ? selectedTypesCount.current++ : selectedTypesCount.current--;
  }

  function handleReviewDialogClose(updatedReport?: Report | RouteReport) {
    if (updatedReport instanceof Report) {
      if (updatedReport) setSelectedReport(updatedReport);
    }

    openReviewDialog(false);
  }

  async function getContribution(): Promise<void> {
    if (!contributionId) return;

    setSelectedReport(undefined);

    try {
      const report = await ReportService.getReport(contributionId);
      setSelectedReport(report);
    } catch (err) {
      console.error(err);
    }
  }

  async function getContributions(): Promise<void> {
    if (!types || !currentZone) return;

    const selectedTypesCodes =
      types.length > 0 ? types.filter(({ id }) => selectedTypes[id]).map(({ code }) => code) : [];

    const updatedReports =
      selectedTypesCodes.length > 0
        ? (
            await cancellablePromise(
              ReportService.getReports({
                period: selectedPeriod?.toIPeriod(),
                cyclabilityZoneId: currentZone.id,
                typeCodes: selectedTypesCodes,
                page: 1,
                rowsPerPage: 500,
                query:
                  '{id, geo_point, creator{username}, created, description, photo, report_type_code, status}',
              }),
            )
          ).reports
        : [];
    setReports(updatedReports);
  }

  function handleContributionFormDialogClose(newReport?: Report) {
    if (newReport) getContributions();

    openContributeFormDialog(false);
  }

  return (
    <>
      {contributionId !== null ? (
        <>
          <Details openReviewDialog={openReviewDialog} />
          {selectedReport && (
            <NewReviewFormDialog
              onClose={handleReviewDialogClose}
              open={reviewDialogOpen}
              report={selectedReport}
            />
          )}
        </>
      ) : (
        <>
          <StyledReportsList
            selectedTypeChanged={selectedTypeChanged}
            selectedTypes={selectedTypes}
            selectedTypesCount={selectedTypesCount.current}
          />
          <Tooltip placement="left" title={<Trans i18nKey="commons.actions.contribute" />}>
            <StyledFab
              color="secondary"
              onClick={() =>
                currentUser ? openContributeFormDialog(true) : openSignInDialog(true)
              }
            >
              <Add color="inherit" />
            </StyledFab>
          </Tooltip>
        </>
      )}
      <ContributionFormDialog
        initialLocation={map?.getCenter()}
        onClose={handleContributionFormDialogClose}
        open={contributeFormDialogOpen}
      />
    </>
  );
}

const StyledReportsList = styled(ReportsList)`
  && {
    padding-bottom: 72px;
  }
`;

const StyledFab = styled(Fab)`
  && {
    bottom: 8px;
    position: absolute;
    right: 8px;
  }
`;
