import { RouteProps } from 'react-router-dom';

import Tabs from './tabs';

type TPaths = '/' | '*' | ':countryCodeOrTab/*' | ':countryCodeOrTab/:zoneCodeOrTabOrId/*';

export const defaultPrivatePath: TPaths = '/';
export const defaultGuestPath: TPaths = '/';

const routes: Array<RouteProps & { path?: TPaths; isPrivate?: boolean; isGuest?: boolean }> = [
  {
    path: '*',
    element: <Tabs />,
  },
  {
    path: ':countryCodeOrTab/*',
    element: <Tabs />,
  },
  {
    path: ':countryCodeOrTab/:zoneCodeOrTabOrId/*',
    element: <Tabs />,
  },
];

export default routes;
