import { TAdministrativeLevel } from '@geovelo-frontends/commons';

export function getChildrenTypeKey(administrativeLevel: TAdministrativeLevel | null): string {
  if (!administrativeLevel) return 'bicycle_facilities.administrative_levels.zone';

  switch (administrativeLevel) {
    case 'world':
      return 'bicycle_facilities.administrative_levels.country';
    case 'country':
      return 'bicycle_facilities.administrative_levels.region';
    case 'region':
      return 'bicycle_facilities.administrative_levels.department';
    case 'department':
      return 'bicycle_facilities.administrative_levels.epci';
    case 'epci':
      return 'bicycle_facilities.administrative_levels.city';
    default:
      return 'bicycle_facilities.administrative_levels.zone';
  }
}
