import {
  CyclabilityZone,
  Partner,
  Period,
  PoiCategory,
  Report,
  ReportType,
  RouteReport,
  User,
} from '@geovelo-frontends/commons';
import { Moment } from 'moment';
import { createContext } from 'react';

import { TTab } from './router/tabs';

import { Map } from '!maplibre-gl';

export interface IUserContext {
  current?: User | null;
  partners?: Partner[] | null;
  signInDialogOpen: boolean;
}

export const worldId = 0;
export const world = new CyclabilityZone(worldId, 'world');

export interface IZoneContext {
  map: { [id: number]: CyclabilityZone };
  codeMap: { [key: string]: number | null };
  parentMap: { [id: number]: number | null };
  childrenMap: { [id: number]: number[] | null };
  current?: CyclabilityZone | null;
  frDpts?: CyclabilityZone[];
}

export interface IStatsContext {
  periods?: Array<{ key: string; date: Moment }>;
  selectedKey: string | null;
  selectedIndex: number | null;
}

export interface IReportContext {
  reports?: Report[];
  types?: ReportType[];
  allTypes?: ReportType[];
  selectedReport?: Report | null;
  selectedPeriod: Period | null;
}

export interface IItineraryContext {
  reports?: RouteReport[];
  selectedReport?: RouteReport | null;
}

export interface IPoiContext {
  categories?: PoiCategory[];
}

export interface IRoutingContext {
  countryCode?: string | null;
  zoneCode?: string | null;
  currentTab: TTab;
  contributionId: number | null;
}

interface IAppActions {
  setMap: (map: Map) => void;
  setCurrentUser: (user?: User | null) => void;
  openSignInDialog: (open: boolean) => void;
  setZoneMap: (map: { [id: number]: CyclabilityZone }) => void;
  setZoneCodeMap: (map: { [key: string]: number | null }) => void;
  setZoneParentMap: (map: { [id: number]: number | null }) => void;
  setZoneChildrenMap: (map: { [id: number]: number[] | null }) => void;
  setCurrentZone: (zone?: CyclabilityZone | null) => void;
  setStatsPeriods: (dates?: Array<{ key: string; date: Moment }>) => void;
  selectStatsKey: (key: string | null) => void;
  selectStatsIndex: (index: number | null) => void;
  setCountryCode: (code: string | null) => void;
  setZoneCode: (code: string | null) => void;
  setCurrentTab: (tab: TTab) => void;
  setContributionId: (id: number | null) => void;
  setReports: (reports?: Report[]) => void;
  setSelectedReport: (report?: Report | null) => void;
  setSelectedPeriod: (period: Period) => void;
  setRouteReports: (reports?: RouteReport[]) => void;
  setSelectedRouteReport: (report?: RouteReport | null) => void;
}

interface IAppContext {
  map: { current?: Map };
  user: IUserContext;
  zone: IZoneContext;
  stats: IStatsContext;
  report: IReportContext;
  routeReport: IItineraryContext;
  pois: IPoiContext;
  routing: IRoutingContext;
  actions: IAppActions;
}

export const AppContext = createContext<IAppContext>({
  map: {},
  user: { signInDialogOpen: false },
  zone: { map: {}, codeMap: {}, parentMap: {}, childrenMap: {} },
  stats: { selectedKey: null, selectedIndex: null },
  report: { selectedReport: null, selectedPeriod: null },
  routeReport: { selectedReport: null },
  pois: {},
  routing: { currentTab: 'stats', contributionId: null },
  actions: {
    setMap: () => undefined,
    setCurrentUser: () => undefined,
    openSignInDialog: () => undefined,
    setZoneMap: () => undefined,
    setZoneCodeMap: () => undefined,
    setZoneParentMap: () => undefined,
    setZoneChildrenMap: () => undefined,
    setCurrentZone: () => undefined,
    setStatsPeriods: () => undefined,
    selectStatsKey: () => undefined,
    selectStatsIndex: () => undefined,
    setCountryCode: () => undefined,
    setZoneCode: () => undefined,
    setCurrentTab: () => undefined,
    setContributionId: () => undefined,
    setReports: () => undefined,
    setSelectedReport: () => undefined,
    setSelectedPeriod: () => undefined,
    setRouteReports: () => undefined,
    setSelectedRouteReport: () => undefined,
  },
});
