import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
} from '@mui/material';

function ListItemSkeleton(): JSX.Element {
  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton height={20} variant="circular" width={20} />
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton variant="text" width={200} />}
        secondary={<Skeleton variant="text" width={300} />}
      />
      <ListItemSecondaryAction>
        <Box display="flex" gap="4px">
          <Skeleton height={24} variant="circular" width={24} />
          <Skeleton height={24} variant="circular" width={24} />
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default ListItemSkeleton;
