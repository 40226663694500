import { GeoveloInlineWhiteIcon } from '@geovelo-frontends/commons';
import { AccountCircle, Folder } from '@mui/icons-material';
import { AppBar, Box, Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';

import ProfileMenu from './profile-menu';

interface IProps {
  openGISFilesDialog: (open: boolean) => void;
}

function Header({ openGISFilesDialog }: IProps): JSX.Element {
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    user: { current: currentUser, partners },
    actions: { openSignInDialog },
  } = useContext(AppContext);

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <Box marginRight={1}>
            <StyledTitle component="h1" key={0} variant="h6">
              <StyledHomeLink key={1} to="/">
                <Trans i18nKey="bicycle_facilities.app_title" />
              </StyledHomeLink>
            </StyledTitle>
            <StyledBy component="span" key={2}>
              <Trans i18nKey="commons.by" />
            </StyledBy>
          </Box>
          <StyledGeoveloLink href={environment.frontendUrl} rel="noreferrer" target="_blank">
            <StyledGeoveloIcon />
          </StyledGeoveloLink>
          <StyledSpacer />
          {partners && partners.length > 0 && (
            <StyledGISFilesButton
              color="inherit"
              onClick={() => openGISFilesDialog(true)}
              size="small"
              startIcon={<Folder />}
              variant="outlined"
            >
              <Trans i18nKey="bicycle_facilities.actions.gis_files" />
            </StyledGISFilesButton>
          )}
          {currentUser && (
            <Tooltip placement="left" title={currentUser.email}>
              <IconButton
                color="inherit"
                onClick={({ currentTarget }) => setProfileMenuAnchorEl(currentTarget)}
                size="small"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
          )}
          {currentUser === null && (
            <Button
              color="inherit"
              onClick={() => openSignInDialog(true)}
              size="small"
              variant="outlined"
            >
              <Trans i18nKey="commons.actions.sign_in" />
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <ProfileMenu anchorEl={profileMenuAnchorEl} onClose={() => setProfileMenuAnchorEl(null)} />
    </>
  );
}

const StyledTitle = styled(Typography)<{ component: 'h1' }>`
  display: inline-block;
`;

const StyledHomeLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const StyledBy = styled(Typography)<{ component: 'span' }>`
  && {
    margin-left: 0.5rem;
    text-transform: lowercase;
  }
`;

const StyledGeoveloLink = styled.a`
  color: inherit;
  height: 24px;
`;

const StyledGeoveloIcon = styled(GeoveloInlineWhiteIcon)`
  && {
    height: 24px;
    width: auto;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

const StyledGISFilesButton = styled(Button)`
  && {
    margin-right: 16px;
  }
`;

export default Header;
