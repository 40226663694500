import { Facilities, useFileSaver } from '@geovelo-frontends/commons';
import { CloudDownload } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { deepOrange, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { getChildrenTypeKey } from '../../utils/cyclability-zone';

import facilities from './facilities';

type TKey = 'name' | Facilities | 'all';

type TCell = { [key in TKey]?: { value: string | number; diff?: number } };

interface IRow {
  id: number;
  cells: TCell;
}

type TProps = Omit<DialogProps, 'onClose'> & { onClose: () => void };

function ChildrenDialog({ ...props }: TProps): JSX.Element {
  const [childrenTypeKey, setChildrenTypeKey] = useState(getChildrenTypeKey(null));
  const [orderBy, setOrderBy] = useState<TKey>('name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [rows, setRows] = useState<IRow[]>([]);
  const [sortedRows, sortRows] = useState<IRow[]>([]);
  const {
    zone: { map: zoneMap, childrenMap, current: currentZone, frDpts },
    stats: { periods, selectedIndex: selectedPeriodIndex, selectedKey: selectedPeriodKey },
  } = useContext(AppContext);
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { transitions } = useTheme();
  const { downloadCSV } = useFileSaver();

  useEffect(() => {
    const children =
      currentZone &&
      (currentZone.administrativeLevel === 'country' &&
      currentZone.countryCode === 'fr' &&
      (!process.env.REACT_APP_CONFIG || process.env.REACT_APP_CONFIG === 'france')
        ? frDpts || []
        : childrenMap[currentZone.id]?.map((id) => zoneMap[id]).filter(Boolean));

    if (props.open && children && selectedPeriodKey) {
      const prevPeriodKey =
        (periods && selectedPeriodIndex !== null && periods[selectedPeriodIndex - 1]?.key) || null;

      setRows([
        ...children.map(({ id, name, stats }) => {
          const periodStats = stats.find(({ dateAsKey }) => dateAsKey === selectedPeriodKey);
          const prevStats =
            (prevPeriodKey && stats.find(({ dateAsKey }) => dateAsKey === prevPeriodKey)) || null;
          const cells: TCell = { name: { value: name } };

          facilities.forEach(({ key }) => {
            const distance = Math.round(periodStats?.distances[key] || 0);
            const diff =
              (prevStats && Math.round(distance - prevStats.distances[key])) || undefined;

            cells[key] = { value: distance, diff };
          });

          return { id, cells };
        }),
      ]);
    } else if (!props.open) {
      setTimeout(() => {
        setRows([]);
      }, transitions.duration.leavingScreen);
    } else {
      setRows([]);
    }
  }, [props.open, currentZone, childrenMap, selectedPeriodKey]);

  useEffect(() => {
    if (currentZone) {
      const { administrativeLevel, countryCode } = currentZone;

      setChildrenTypeKey(
        getChildrenTypeKey(
          (administrativeLevel === 'country' &&
            countryCode === 'fr' &&
            (!process.env.REACT_APP_CONFIG || process.env.REACT_APP_CONFIG === 'france') &&
            'region') ||
            administrativeLevel,
        ),
      );
    }
  }, [currentZone]);

  useEffect(() => {
    sortRows([
      ...rows.sort(({ cells: aCells }, { cells: bCells }) => {
        const aValue = aCells[orderBy]?.value;
        const bValue = bCells[orderBy]?.value;

        if (typeof aValue === 'number' && typeof bValue === 'number')
          return order === 'asc' ? aValue - bValue : bValue - aValue;
        if (typeof aValue === 'string' && typeof bValue === 'string')
          return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);

        return 0;
      }),
    ]);
  }, [rows, orderBy, order]);

  function handleSort(key: TKey) {
    setOrder(
      orderBy === key ? (order === 'asc' ? 'desc' : 'asc') : key === 'name' ? 'asc' : 'desc',
    );
    setOrderBy(key);
  }

  function handleDownload() {
    if (!currentZone) return;

    downloadCSV(
      `${currentZone.name.replace(/\s+/g, '_').toLowerCase()}_${selectedPeriodKey}.csv`,
      headers.map(({ title }) => title),
      rows.map(({ cells }) =>
        headers.map(({ key }) => {
          const value = cells[key]?.value;

          return key === 'name' ? `"${value || ''}"` : value || 0;
        }),
      ),
    );
  }

  const headers: Array<{ key: TKey; title: string }> = [
    { key: 'name', title: t(childrenTypeKey, { count: 1 }) },
    ...facilities.map(({ key, titleKey }) => ({ key, title: `${t(titleKey)} (km)` })),
  ];

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <DialogTitle>
        <Trans
          i18nKey="bicycle_facilities.stats.children_dialog.title"
          values={{
            type:
              currentZone?.administrativeLevel !== 'department' || language !== 'fr'
                ? t(childrenTypeKey, { context: 'other' }).toLowerCase()
                : t(childrenTypeKey, { context: 'other' }),
          }}
        />
      </DialogTitle>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map(({ key, title }) => (
                <TableCell key={key} sortDirection={orderBy === key ? order : false}>
                  <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : 'asc'}
                    onClick={() => handleSort(key)}
                  >
                    {title}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map(({ id, cells }) => (
              <TableRow key={id}>
                {headers.map(({ key }) => {
                  const cell = cells[key];
                  if (!cell) return <TableCell key={key} />;

                  const { value, diff } = cell;

                  return (
                    <TableCell key={key}>
                      {value}
                      <StyledDiff
                        className={diff && diff >= 0 ? 'improvement' : ''}
                        variant="caption"
                      >
                        {diff && (
                          <>
                            &nbsp; ({diff > 0 ? '+' : '-'}
                            {Math.abs(diff)})
                          </>
                        )}
                      </StyledDiff>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleDownload}
          size="small"
          startIcon={<CloudDownload />}
          variant="outlined"
        >
          CSV
        </Button>
        <Button onClick={props.onClose} size="small" variant="outlined">
          <Trans i18nKey="commons.actions.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledDiff = styled(Typography)`
  color: ${deepOrange[500]};

  &.improvement {
    color: ${green[500]};
  }
`;

export default ChildrenDialog;
