import {
  CancelledPromiseError,
  CyclabilityZone,
  CyclabilityZoneService,
  CyclabilityZoneStats,
  Facilities,
  TAdministrativeLevel,
  TBackendAdministrativeLevel,
  TGetZoneProps,
  administrativeLevelsValues,
  backendAdministrativeLevels,
  getCyclabilityZonesPeriods,
  useCancellablePromise,
  useTracker,
} from '@geovelo-frontends/commons';
import { useContext, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { environment } from '../../environment';
import ContentLayout from '../../layout/content';
import { AppContext, worldId } from '../context';

export const tabs = ['stats', 'contributions', 'route-issues'] as const;

export type TTab = (typeof tabs)[number];

function Tabs(): JSX.Element {
  const {
    zone: { map: zoneMap, codeMap: zoneCodeMap, parentMap, childrenMap },
    actions: {
      setZoneMap,
      setZoneCodeMap,
      setZoneParentMap,
      setZoneChildrenMap,
      setCountryCode,
      setZoneCode,
      setCurrentZone,
    },
  } = useContext(AppContext);
  const { countryCodeOrTab, zoneCodeOrTabOrId } = useParams();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { trackPageView } = useTracker();

  useEffect(() => {
    trackPageView();
  }, []);

  useEffect(() => {
    const countryCode =
      countryCodeOrTab !== undefined && !tabs.find((key) => key === countryCodeOrTab)
        ? countryCodeOrTab
        : null;
    const zoneCode =
      zoneCodeOrTabOrId !== undefined && !tabs.find((key) => key === zoneCodeOrTabOrId)
        ? zoneCodeOrTabOrId
        : null;

    setCountryCode(countryCode);
    setZoneCode(zoneCode);
    update(countryCode, zoneCode);
  }, [countryCodeOrTab, zoneCodeOrTabOrId]);

  async function update(countryCode: string | null, zoneCode: string | null) {
    cancelPromises();
    setCurrentZone();

    const zone = await getZone(countryCode, zoneCode);
    setCurrentZone(zone);

    if (zone) {
      getParent(zone);
      getChildren(zone);
    }
  }

  async function getZone(
    countryCode: string | null,
    zoneCode: string | null,
  ): Promise<CyclabilityZone | null | undefined> {
    if (!countryCode)
      return environment.config.maxAdministrativeLevel === 'world' ? zoneMap[worldId] : null;

    const code = zoneCode || countryCode;
    const zoneId = zoneCodeMap[code];
    if (zoneId === null) return null;

    if (zoneId !== undefined && zoneMap[zoneId]) return zoneMap[zoneId];

    try {
      const { minAdministrativeLevel } = environment.config;
      const minAdministrativeLevelValue = administrativeLevelsValues[minAdministrativeLevel];
      const params: TGetZoneProps = { administrativeLevel: 'COUNTRY' };
      let administrativeLevel: Exclude<TAdministrativeLevel, 'world'> = 'country';

      if (!zoneCode) params.countryCode = countryCode;
      else {
        const ref = zoneCode.split('_')[1];

        if (!ref || ref.length === 0) {
          administrativeLevel = 'region';
          params.name = zoneCode;
        } else if (ref.length === 2) {
          administrativeLevel = 'department';
          params.ref = ref;
        } else if (ref.length === 9 || ref[0] === 'T') {
          administrativeLevel = 'epci';
          params.ref = ref;
        } else if (ref.length === 5) {
          administrativeLevel = 'city';
          params.ref = ref;
        } else {
          throw new Error('zone code cannot be parsed');
        }
      }

      if (minAdministrativeLevelValue > administrativeLevelsValues[administrativeLevel])
        return (zoneCodeMap[code] = null);

      params.administrativeLevel = backendAdministrativeLevels[administrativeLevel];

      const {
        zones: [zone],
      } = await cancellablePromise(CyclabilityZoneService.getZones(params));

      if (zone) zoneMap[zone.id] = zone;
      zoneCodeMap[code] = zone?.id || null;

      setZoneMap({ ...zoneMap });
      setZoneCodeMap({ ...zoneCodeMap });

      return zone;
    } catch (err) {
      if (!(err instanceof CancelledPromiseError)) {
        zoneCodeMap[code] = null;
        setZoneCodeMap({ ...zoneCodeMap });

        return null;
      }
    }
  }

  async function getParent(zone: CyclabilityZone): Promise<void> {
    const parentId = parentMap[zone.id];
    if (parentId === null || (parentId !== undefined && zoneMap[parentId])) return;

    if (
      zone.administrativeLevel === environment.config.maxAdministrativeLevel ||
      zone.administrativeLevel === 'world'
    ) {
      parentMap[zone.id] = null;
      setZoneParentMap({ ...parentMap });

      return;
    }

    if (zone.administrativeLevel === 'country') {
      parentMap[zone.id] = worldId;
      setZoneParentMap({ ...parentMap });

      return;
    }

    try {
      const parent = await cancellablePromise(
        CyclabilityZoneService.getParentZone(zone, { query: '{-geo_polygon}' }),
      );

      if (parent) {
        zoneMap[parent.id] = parent;
        zoneCodeMap[parent.code] = parent.id;

        setZoneMap({ ...zoneMap });
        setZoneCodeMap({ ...zoneCodeMap });
      }

      parentMap[zone.id] = parent?.id || null;
      setZoneParentMap({ ...parentMap });
    } catch (err) {
      if (!(err instanceof CancelledPromiseError)) {
        parentMap[zone.id] = null;
        setZoneParentMap({ ...parentMap });
      }
    }
  }

  async function getChildren(zone: CyclabilityZone): Promise<void> {
    const childrenIds = childrenMap[zone.id];
    if (childrenIds !== undefined) return;

    if (
      zone.administrativeLevel === environment.config.minAdministrativeLevel ||
      zone.administrativeLevel === 'city'
    ) {
      childrenMap[zone.id] = null;
      setZoneChildrenMap({ ...childrenMap });

      return;
    }

    try {
      let children: CyclabilityZone[] = [];

      if (zone.administrativeLevel === 'world') {
        ({ zones: children } = await cancellablePromise(
          CyclabilityZoneService.getZones({
            administrativeLevel: 'COUNTRY',
            rowsPerPage: 100,
            query:
              '{id, country_code, code, reference, name, administrative_level, geo_polygon_simplified, stats}',
          }),
        ));

        const periods = getCyclabilityZonesPeriods(children);
        const initialDistances: { [key in Facilities | 'all']: number } = {
          cycleways: 0,
          greenways: 0,
          lanes: 0,
          mixedfacilities: 0,
          opposites: 0,
          sharedBusways: 0,
          all: 0,
        };
        const stats = periods.reduce<{ [key: string]: CyclabilityZoneStats }>(
          (res, { key, date }) => {
            res[key] = new CyclabilityZoneStats(date, { ...initialDistances });

            return res;
          },
          {},
        );

        const distanceKeys: (Facilities | 'all')[] = [
          Facilities.Cycleways,
          Facilities.Greenways,
          Facilities.Lanes,
          Facilities.MixedFacilities,
          Facilities.Opposites,
          Facilities.SharedBusways,
          'all',
        ];
        children.forEach(({ stats: childStats }) => {
          periods.forEach(({ key: periodKey }) => {
            const childPeriodStats = childStats.find(({ dateAsKey }) => dateAsKey === periodKey);
            if (childPeriodStats) {
              distanceKeys.forEach((key) => {
                stats[periodKey].distances[key] += childPeriodStats.distances[key];
              });
            }
          });
        });

        zoneMap[worldId].stats = periods.map(({ key }) => stats[key]);
      } else {
        let administrativeLevel: TBackendAdministrativeLevel;
        switch (zone.administrativeLevel) {
          case 'country':
            administrativeLevel = 'REGION';
            break;
          case 'region':
            administrativeLevel = 'DEPARTMENT';
            break;
          case 'department':
            administrativeLevel = 'EPCI';
            break;
          default:
            administrativeLevel = 'CITY';
            break;
        }

        children = await cancellablePromise(
          CyclabilityZoneService.getChildrenZones(zone.id, {
            administrativeLevel,
            query: '{-geo_polygon}',
          }),
        );
      }

      children.forEach((child) => {
        zoneMap[child.id] = child;
        zoneCodeMap[child.code] = child.id;
      });

      setZoneMap({ ...zoneMap });
      setZoneCodeMap({ ...zoneCodeMap });

      childrenMap[zone.id] = children.map(({ id }) => id);
      setZoneChildrenMap({ ...childrenMap });
    } catch (err) {
      if (!(err instanceof CancelledPromiseError)) {
        childrenMap[zone.id] = null;
        setZoneChildrenMap({ ...childrenMap });
      }
    }
  }

  return (
    <Routes>
      <Route index element={<ContentLayout />} />
      <Route element={<ContentLayout />} path=":tabOrId" />
      <Route element={<ContentLayout />} path=":tabOrId/:id" />
    </Routes>
  );
}

export default Tabs;
