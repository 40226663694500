import { SentimentDissatisfied } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

function EmptyState({ description }: { description: ReactNode }): JSX.Element {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" gap="8px" padding="24px">
      <SentimentDissatisfied color="action" style={{ height: 64, width: 64 }} />
      <Typography align="center" color="textSecondary">
        {description}
      </Typography>
    </Box>
  );
}

export default EmptyState;
