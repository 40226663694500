import { useUnits } from '@geovelo-frontends/commons';
import { Card, CardHeader, Grid as MuiGrid, Skeleton, Typography } from '@mui/material';
import { deepOrange, green, orange } from '@mui/material/colors';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';

import facilities from './facilities';

function Grid(): JSX.Element {
  const {
    zone: { current: currentZone },
    stats: { periods, selectedKey, selectedIndex },
  } = useContext(AppContext);
  const { toDistance } = useUnits();

  const stats = currentZone?.stats.find(({ dateAsKey }) => dateAsKey === selectedKey) || null;
  const prevKey = (periods && selectedIndex !== null && periods[selectedIndex - 1]?.key) || null;

  return (
    <Wrapper>
      <MuiGrid container spacing={2}>
        {facilities.map(({ key, icon, titleKey }) => {
          const distance = stats ? stats.distances[key] * 1000 : null;
          const prevStats =
            (prevKey && currentZone?.stats.find(({ dateAsKey }) => dateAsKey === prevKey)) || null;
          const diff =
            (distance && prevStats && distance - prevStats.distances[key] * 1000) || null;
          // const tmpDistance = stats ? Math.round(stats.tmpDistances[key]) : null;
          const tmpDistance = 0;

          return (
            <MuiGrid item key={key} xs={6}>
              <StyledCard className={key === 'all' ? 'total' : ''} variant="outlined">
                <CardHeader
                  avatar={icon}
                  subheader={
                    stats ? (
                      <>
                        <Typography
                          component="span"
                          style={{
                            color: 'rgba(0, 0, 0, 0.87)',
                            display: 'inline-block',
                            fontSize: '1.1rem',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {toDistance(distance, true, 'km')}
                          &nbsp;
                        </Typography>
                        <StyledDiff
                          className={diff && diff >= 0 ? 'improvement' : ''}
                          variant="caption"
                        >
                          {diff && (
                            <>
                              ({diff > 0 ? '+' : '-'}
                              {toDistance(Math.abs(diff), true, 'km')})
                            </>
                          )}
                        </StyledDiff>
                        {environment.enableTmpFacilities && key !== 'all' && tmpDistance ? (
                          <>
                            <br />
                            <StyledTmpDistance variant="caption">
                              <Trans
                                count={tmpDistance}
                                i18nKey="bicycle_facilities.stats.facilities.tmp_distance"
                                values={{ count: tmpDistance }}
                              />
                            </StyledTmpDistance>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <Typography component="div" style={{ fontSize: '1.1rem' }}>
                        <Skeleton variant="text" width={100} />
                      </Typography>
                    )
                  }
                  title={
                    <Typography color="textSecondary" variant="caption">
                      <Trans i18nKey={titleKey} />
                    </Typography>
                  }
                />
              </StyledCard>
            </MuiGrid>
          );
        })}
      </MuiGrid>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledCard = styled(Card)`
  height: calc(100% - 2px);
  min-height: ${() => `${environment.enableTmpFacilities ? 112 : 92}px`};

  &.total {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledDiff = styled(Typography)`
  color: ${deepOrange[500]};
  white-space: nowrap;

  &.improvement {
    color: ${green[500]};
  }
`;

const StyledTmpDistance = styled(Typography)`
  color: ${orange[500]};
`;

export default Grid;
