import { Tab, TabProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

import { TTab } from '../../app/router/tabs';

function LinkTab({
  value,
  ...props
}: { value: TTab } & Omit<TabProps<'a'>, 'value'> & LinkProps): JSX.Element {
  return <Tab component={Link} {...props} value={value} />;
}

export default LinkTab;
