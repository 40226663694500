import {
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  SpeakerNotesOff as NoResponseIcon,
  SpeakerNotes as ResponseIcon,
  Today,
} from '@mui/icons-material';
import {
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import EmptyState from '../../components/empty-state';
import ListItemSkeleton from '../../components/list-item-skeleton';
import PeriodDialog from '../../components/period-dialog';
import { getPeriodTitle } from '../../utils/period';

function RouteReportsList(): JSX.Element {
  const [periodDialogOpen, openPeriodDialog] = useState(false);
  const {
    zone: { current: currentZone },
    report: { selectedPeriod },
    routeReport: { reports },
  } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <>
      <FiltersWrapper>
        <Button
          disableElevation
          endIcon={<Today />}
          onClick={() => openPeriodDialog(true)}
          style={{ textTransform: 'none' }}
          variant="contained"
        >
          <Trans i18nKey={getPeriodTitle(selectedPeriod)} />
        </Button>
      </FiltersWrapper>
      <List component="nav">
        {currentZone && reports ? (
          reports.length > 0 ? (
            reports.map(({ id, created, creator, status, response }) => (
              <ListItemButton
                component={Link}
                key={id}
                to={
                  currentZone.administrativeLevel === 'world'
                    ? `/route-issues/${id}`
                    : currentZone.administrativeLevel === 'country'
                      ? `/${currentZone.countryCode}/route-issues/${id}`
                      : `/${currentZone.countryCode}/${currentZone.code}/route-issues/${id}`
                }
              >
                {status === 'OPEN' ? (
                  <ListItemIcon>
                    <ErrorIcon color="error" />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={t('bicycle_facilities.route_reports.list.route_report_title', {
                    id,
                  })}
                  secondary={t('bicycle_facilities.route_reports.list.route_report_subtitle', {
                    creator,
                    created: moment(created).format('LL'),
                  })}
                />
                <ListItemSecondaryAction>
                  {!!response ? (
                    <Tooltip
                      title={<Trans i18nKey="bicycle_facilities.route_reports.list.has_response" />}
                    >
                      <ResponseIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <Trans i18nKey="bicycle_facilities.route_reports.list.has_no_response" />
                      }
                    >
                      <NoResponseIcon />
                    </Tooltip>
                  )}
                </ListItemSecondaryAction>
              </ListItemButton>
            ))
          ) : (
            <EmptyState
              description={<Trans i18nKey="bicycle_facilities.route_reports.list.empty_state" />}
            />
          )
        ) : (
          <ListItemSkeleton />
        )}
      </List>
      <PeriodDialog onClose={() => openPeriodDialog(false)} open={periodDialogOpen} />
    </>
  );
}

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 16px 16px 0;
`;

export default RouteReportsList;
