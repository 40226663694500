import { RouteReport, RouteReportService, useCancellablePromise } from '@geovelo-frontends/commons';
import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { AppContext } from '../../app/context';

import Details from './details';
import RouteReportsList from './list';
import ResponseDialog from './response-dialog';

export function Itineraries(): JSX.Element {
  const {
    user: { current: currentUser },
    zone: { current: currentZone },
    report: { selectedPeriod },
    routeReport: { reports, selectedReport },
    routing: { contributionId },
    actions: { setRouteReports, setSelectedRouteReport },
  } = useContext(AppContext);
  const [responseDialogOpen, openResponseDialog] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    setRouteReports(undefined);
    if (currentUser && contributionId === null) getItinerariesReports();
  }, [currentUser, currentZone, selectedPeriod, contributionId]);

  useEffect(() => {
    if (currentUser && contributionId) getItineraryReport();
    else setSelectedRouteReport(null);
  }, [currentUser, contributionId]);

  async function getItineraryReport(): Promise<void> {
    if (!contributionId) return;

    setSelectedRouteReport(undefined);

    try {
      const report = await RouteReportService.getRouteReport(contributionId);
      setSelectedRouteReport(report);
    } catch (err) {
      console.error(err);
    }
  }

  async function getItinerariesReports(): Promise<void> {
    if (!currentZone || !selectedPeriod) return;

    const updatedReports = await cancellablePromise(
      RouteReportService.getRouteReports({
        cyclabilityZoneId: currentZone.id,
        period: selectedPeriod.toIPeriod(),
      }),
    );
    setRouteReports(updatedReports);
  }

  function handleReviewDialogClose(routeReport?: RouteReport) {
    if (routeReport) {
      reports?.splice(
        reports?.findIndex(({ id }) => id === routeReport.id),
        1,
        routeReport,
      );
      setRouteReports([...(reports || [])]);
      setSelectedRouteReport(routeReport);
    }

    openResponseDialog(false);
  }

  if (currentUser === null) return <Navigate to="/" />;

  if (currentUser === undefined) return <></>;

  return (
    <>
      {contributionId != null ? (
        <>
          <Details openResponseDialog={openResponseDialog} />
          {selectedReport && (
            <ResponseDialog
              onClose={handleReviewDialogClose}
              open={responseDialogOpen}
              routeReportId={selectedReport.id}
            />
          )}
        </>
      ) : (
        <RouteReportsList />
      )}
    </>
  );
}
