import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
}

function Content({ children }: IProps): JSX.Element {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  &.column-reverse {
    flex-direction: column-reverse;
  }
`;

export default Content;
