import {
  TLanguage,
  defaultLanguage as _defaultLanguage,
  languages as _languages,
  enCommonsTranslations,
  frCommonsTranslations,
} from '@geovelo-frontends/commons';
import i18n, { ResourceKey } from 'i18next';
import { merge } from 'lodash';
import { initReactI18next } from 'react-i18next';

import en from '../translations/en.json';
import fr from '../translations/fr.json';

export type TAppLanguage = TLanguage;

export const languages: {
  [key in TAppLanguage]?: {
    key: TAppLanguage;
    translation: ResourceKey;
    icon: JSX.Element;
    label: string;
  };
} = {
  en: { ..._languages['en'], key: 'en', translation: merge(enCommonsTranslations, en) },
  fr: { ..._languages['fr'], key: 'fr', translation: merge(frCommonsTranslations, fr) },
};

export const defaultLanguage =
  process.env.REACT_APP_CONFIG === 'carto-velo' ? 'fr' : _defaultLanguage;

i18n.use(initReactI18next).init({
  resources: languages,
  lng: defaultLanguage,
  fallbackLng: process.env.REACT_APP_CONFIG === 'carto-velo' ? 'fr' : 'en',
  debug: process.env.NODE_ENV === 'development',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
