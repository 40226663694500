import { AccountCircle as AccountIcon } from '@mui/icons-material';
import { Button, Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';

interface IProps {
  openResponseDialog: (open: boolean) => void;
}

function Details({ openResponseDialog }: IProps): JSX.Element {
  const {
    user: { current: currentUser },
    routeReport: { selectedReport },
    actions: { openSignInDialog },
  } = useContext(AppContext);

  return (
    <>
      {selectedReport && (
        <>
          <StyledCard>
            <CardContent>
              <Typography variant="body1">{selectedReport.description}</Typography>
              <StyledTypography variant="caption">
                <Trans
                  i18nKey="bicycle_facilities.reports.list.report_subtitle"
                  values={{
                    created: moment(selectedReport.created).format('LLL'),
                    creator: selectedReport.creator || '',
                  }}
                />
              </StyledTypography>
            </CardContent>
          </StyledCard>
          <StyledResponse>
            <StyledReviewsTitle>
              {currentUser && !selectedReport.response && (
                <Button
                  color="primary"
                  onClick={() => openResponseDialog(true)}
                  variant="contained"
                >
                  <Trans i18nKey="bicycle_facilities.actions.answer" />
                </Button>
              )}
            </StyledReviewsTitle>
            {!currentUser && !selectedReport.response && (
              <StyledConnexion>
                <Typography variant="body2">
                  <Trans i18nKey="bicycle_facilities.route_reports.details.not_signedin" />
                </Typography>
                <StyledButton
                  color="primary"
                  onClick={() => openSignInDialog(true)}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.sign_in" />
                </StyledButton>
              </StyledConnexion>
            )}
            {selectedReport.response && (
              <StyledReview>
                <AccountIcon color="secondary" />
                <Card>
                  <CardContent>
                    <Typography variant="body2">{selectedReport.response}</Typography>
                  </CardContent>
                </Card>
              </StyledReview>
            )}
          </StyledResponse>
        </>
      )}
    </>
  );
}

const StyledCard = styled(Card)`
  margin: 16px;
`;

const StyledResponse = styled.div`
  margin: 24px;

  > * {
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;

const StyledReviewsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledReview = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;

  > * {
    &:first-child {
      margin-right: 16px;
    }

    &:last-child {
      flex-grow: 1;
    }
  }
`;

const StyledConnexion = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  && {
    margin-left: 16px;
    min-width: 150px;
  }
`;

const StyledTypography = styled(Typography)`
  display: block;
  text-align: end;
`;

export default Details;
