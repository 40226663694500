import { InfoOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { Fragment } from 'react';
import { Trans } from 'react-i18next';

import facilities from './facilities';

type TProps = Omit<DialogProps, 'onClose'> & { onClose: () => void };

function OSMDialog(props: TProps): JSX.Element {
  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <DialogTitle>
        <Trans i18nKey="bicycle_facilities.stats.osm_dialog.title" />
      </DialogTitle>
      <List>
        {facilities
          .filter(({ key }) => key !== 'all')
          .map(({ key, icon, titleKey, descriptionKey, osmLink }, index) => (
            <Fragment key={key}>
              {index > 0 && <Divider />}
              <ListItem>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={<Trans i18nKey={titleKey} />}
                  secondary={descriptionKey && <Trans i18nKey={descriptionKey} />}
                />
                {osmLink && (
                  <ListItemSecondaryAction>
                    <IconButton
                      component="a"
                      href={osmLink}
                      rel="noreferrer"
                      size="small"
                      target="_blank"
                    >
                      <InfoOutlined />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </Fragment>
          ))}
      </List>
      <DialogActions>
        <Button onClick={props.onClose} size="small" variant="outlined">
          <Trans i18nKey="commons.actions.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OSMDialog;
