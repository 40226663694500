import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import { ButtonBase, Paper, useMediaQuery } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Map, { TMapRef } from './map';

interface IProps {
  children: ReactNode;
}

function Content({ children }: IProps): JSX.Element {
  const [containerShowed, showContainer] = useState(true);
  const mapRef = useRef<TMapRef | null>(null);
  const smallDevice = useMediaQuery('(max-width:999px)');

  useEffect(() => {
    setTimeout(() => mapRef.current?.resize(), 200);
  }, [containerShowed]);

  useEffect(() => {
    setTimeout(() => mapRef.current?.resize(), 100);
  }, [smallDevice]);

  return (
    <Wrapper className={smallDevice ? 'column-reverse' : ''}>
      <StyledContainer
        className={[smallDevice ? 'full-width' : '', containerShowed ? '' : 'hidden'].join(' ')}
      >
        {children}
      </StyledContainer>
      <StyledMap ref={mapRef}>
        {smallDevice ? (
          <StyledColumnContainerToggle onClick={() => showContainer(!containerShowed)}>
            {containerShowed ? <ExpandMore /> : <ExpandLess />}
          </StyledColumnContainerToggle>
        ) : (
          <StyledRowContainerToggle onClick={() => showContainer(!containerShowed)}>
            {containerShowed ? <ChevronLeft /> : <ChevronRight />}
          </StyledRowContainerToggle>
        )}
      </StyledMap>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;

  &.column-reverse {
    flex-direction: column-reverse;
  }
`;

const StyledContainer = styled(Paper)`
  && {
    border-radius: 0;
    flex-shrink: 0;
    max-width: 100%;
    overflow-x: hidden;
    transition: width 100ms;
    width: 500px;
    z-index: 2;

    &.hidden {
      width: 0;
    }

    &.full-width {
      height: 50%;
      transition: height 100ms;
      width: 100%;

      &.hidden {
        height: 0;
      }
    }
  }
`;

const StyledMap = styled(Map)`
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
`;

const containerToggleStyle = css`
  background-color: white;
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0px rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  left: 0;
  position: absolute;
`;

const StyledRowContainerToggle = styled(ButtonBase)`
  && {
    ${containerToggleStyle};
    border-radius: 0 0 4px 0;
    top: 0;
    z-index: 2;
  }
`;

const StyledColumnContainerToggle = styled(ButtonBase)`
  && {
    ${containerToggleStyle};
    border-radius: 0 4px 0 0;
    bottom: 0;
    z-index: 2;
  }
`;

export default Content;
