import {
  Autocomplete,
  FileInput,
  GeocoderService,
  IPoint,
  Place,
  Report,
  ReportService,
  TFile,
  useGeoveloMap,
} from '@geovelo-frontends/commons';
import { Info } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppContext } from '../app/context';

import { Marker } from '!maplibre-gl';

const mapId = 'new-report-map';

interface IValues {
  type: number | '';
  description: string;
}

function ContributionFormDialog({
  initialLocation,
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  initialLocation: IPoint | undefined;
  onClose: (newReport?: Report) => void;
}): JSX.Element {
  const markerRef = useRef<Marker>();
  const [location, setLocation] = useState<Place | null>(null);
  const [locationError, setLocationError] = useState(false);
  const [file, setFile] = useState<TFile | null | undefined>(null);
  const {
    report: { types: reportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isSubmitting, values, touched, errors, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        type: '',
        description: '',
      },
      validationSchema: Yup.object().shape({
        type: Yup.number().required(),
        description: Yup.string().required(),
      }),
      onSubmit,
      enableReinitialize: true,
    });
  const {
    map,
    init: initMap,
    destroy: destroyMap,
  } = useGeoveloMap({
    smallDevice: true,
    baseLayer: 'geovelo',
  });

  useEffect(() => {
    if (props.open) {
      setTimeout(
        () => initMap({ container: mapId, customZoomControls: true, baseLayersControl: true }),
        theme.transitions.duration.enteringScreen,
      );
    } else destroyMap();
  }, [props.open]);

  useEffect(() => {
    if (map) {
      markerRef.current = new Marker({ color: theme.palette.secondary.main, draggable: true }).on(
        'dragend',
        ({ target }: { target: Marker }) => {
          const lngLat = target.getLngLat();
          const { lat, lng } = lngLat;

          setLocation(new Place(-1, { type: 'Point', coordinates: [lng, lat] }));
          map.flyTo({ center: lngLat, zoom: Math.max(15, map.getZoom()) });
        },
      );
    }
  }, [map]);

  useEffect(() => {
    if (props.open && reportTypes) {
      setValues({ type: reportTypes[0].id, description: '' });
      setFile(null);
    }
  }, [props.open, reportTypes]);

  useEffect(() => {
    if (props.open && map && initialLocation) {
      const { lat, lng } = initialLocation;
      setLocation(new Place(undefined, { type: 'Point', coordinates: [lng, lat] }));

      map.flyTo({ animate: false, center: initialLocation, zoom: 15 });
      markerRef.current?.setLngLat(initialLocation).addTo(map);
    }
  }, [props.open, initialLocation]);

  useEffect(() => {
    let active = true;

    if (location && !location.addressDetail) {
      try {
        GeocoderService.reverseGeocode(undefined, location.point, (place) => {
          if (active) setLocation(place);
        });
      } catch (err) {
        console.error(err);
      }
    }

    return () => {
      active = false;
    };
  }, [location]);

  useEffect(() => {
    if (isSubmitting && markerRef.current) {
      markerRef.current.setDraggable(!isSubmitting);
    }
  }, [isSubmitting]);

  async function onSubmit(
    { type, description }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    if (!location) {
      setLocationError(true);

      return;
    }

    if (!type || !description) return;

    setSubmitting(true);
    setLocationError(false);

    try {
      const newReport = await ReportService.addReport({
        type,
        place: location,
        description,
        photo: file instanceof File ? file : undefined,
      });

      enqueueSnackbar(t('bicycle_facilities.contribute.sent'), {
        variant: 'success',
      });

      onClose(newReport);
    } catch (err) {
      enqueueSnackbar(t('bicycle_facilities.contribute.not_sent'), {
        variant: 'error',
      });
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      keepMounted
      maxWidth="sm"
      onClose={(_, reason) => reason !== 'backdropClick' && onClose()}
      scroll="body"
      {...props}
    >
      <DialogTitle>
        <Trans i18nKey="commons.actions.contribute" />
      </DialogTitle>
      {props.open && <StyledMap id={mapId} />}
      <form onSubmit={handleSubmit}>
        <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Card style={{ marginBottom: 8 }} variant="outlined">
            <CardContent>
              <Box alignItems="center" display="flex" gap="16px">
                <Info color="primary" />
                <Typography variant="body2">
                  <Trans i18nKey="bicycle_facilities.contribute.info" />
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Autocomplete
            defaultValue={location}
            disabled={isSubmitting}
            error={locationError}
            label={t('bicycle_facilities.contribute.form.location') || ''}
            onSelect={(place) => {
              setLocation(place);
              if (place) {
                const [lng, lat] = place.point.coordinates;
                markerRef.current?.setLngLat({ lat, lng });
                map?.flyTo({ animate: false, center: { lat, lng }, zoom: 15 });
              }
            }}
            size="small"
          />
          <FormControl
            fullWidth
            required
            disabled={isSubmitting}
            margin="none"
            size="small"
            variant="outlined"
          >
            <InputLabel htmlFor="report-type-input">
              <Trans i18nKey="bicycle_facilities.contribute.form.type" />
            </InputLabel>
            <Select
              inputProps={{
                id: 'report-type-input',
              }}
              label={`${t('bicycle_facilities.contribute.form.type')} *`}
              name="type"
              onChange={handleChange}
              value={values.type}
            >
              {reportTypes?.map(({ id, titleKey }) => (
                <MenuItem key={id} value={id}>
                  <Trans i18nKey={titleKey} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            required
            disabled={isSubmitting}
            error={touched.description && Boolean(errors.description)}
            id="description"
            label={t('bicycle_facilities.contribute.form.description')}
            margin="none"
            name="description"
            onChange={handleChange}
            rows={3}
            value={values.description}
            variant="outlined"
          />
          <FileInput disabled={isSubmitting} file={file} onChange={setFile} type="image" />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={() => onClose()} type="reset">
            <Trans i18nKey="commons.actions.close" />
          </Button>
          <Button
            color="primary"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            onClick={() => undefined}
            type="submit"
            variant="contained"
          >
            <Trans i18nKey="commons.actions.add" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const StyledMap = styled.div`
  flex-shrink: 0;
  height: 300px;
  position: relative;
`;

export default ContributionFormDialog;
