import { RouteReport, RouteReportService } from '@geovelo-frontends/commons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface IValues {
  response: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  routeReportId: number;
  onClose: (routeReport?: RouteReport) => void;
};

function ResponseDialog({ routeReportId, onClose, ...props }: TProps): JSX.Element {
  const { isSubmitting, values, touched, errors, handleChange, handleSubmit } = useFormik<IValues>({
    initialValues: { response: '' },
    validationSchema: Yup.object().shape({
      response: Yup.string().min(10).required(),
    }),
    onSubmit,
  });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function onSubmit({ response }: IValues, { setSubmitting }: FormikHelpers<IValues>) {
    setSubmitting(true);

    try {
      const updatedRouteReport = await RouteReportService.updateRouteReport(routeReportId, {
        response,
      });

      onClose(updatedRouteReport);
    } catch {
      enqueueSnackbar(t('commons.report.reviews.not_added'));
    }

    setSubmitting(false);
  }

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Trans i18nKey="bicycle_facilities.route_reports.response" />
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            disabled={isSubmitting}
            error={touched.response && Boolean(errors.response)}
            id="response"
            inputProps={{ min: 10 }}
            label={<Trans i18nKey="bicycle_facilities.route_reports.details.response_title" />}
            margin="dense"
            name="response"
            onChange={handleChange}
            rows={3}
            value={values.response}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={() => onClose()} size="small" variant="outlined">
            <Trans i18nKey="commons.actions.close" />
          </Button>
          <Button
            color="primary"
            disabled={isSubmitting}
            size="small"
            type="submit"
            variant="contained"
          >
            <Trans i18nKey="commons.actions.send" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ResponseDialog;
