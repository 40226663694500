import { Autocomplete, Place } from '@geovelo-frontends/commons';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { createRoot } from 'react-dom/client';
import styled from 'styled-components';

import { IControl, Map } from '!maplibre-gl';

const id = 'places-autocomplete';

class SearchControl implements IControl {
  private map?: Map;
  private container?: HTMLDivElement;

  constructor(private readonly id: string) {}

  onAdd(_map: Map): HTMLDivElement {
    this.map = _map;
    this.container = document.createElement('div');
    this.container.className = 'maplibregl-ctrl';

    const content = document.createElement('div');
    content.id = this.id;
    this.container.appendChild(content);

    return this.container;
  }

  init(theme: Theme, { onSelect }: { onSelect: (place: Place) => void }): void {
    const ele = document.getElementById(this.id);
    if (!ele) return;

    const container = createRoot(ele);
    container.render(
      <SearchControlComponent
        map={this.map}
        onSelect={(place) => {
          const {
            point: {
              coordinates: [lng, lat],
            },
          } = place;

          this.map?.flyTo({ center: { lat, lng }, zoom: 17 });
          onSelect(place);
        }}
        theme={theme}
      />,
    );
  }

  onRemove(): void {
    this.container?.parentNode?.removeChild(this.container);
    this.map = undefined;
  }
}

function SearchControlComponent({
  map,
  theme,
  onSelect,
}: {
  map?: Map;
  onSelect: (place: Place) => void;
  theme: Theme;
}): JSX.Element {
  const [defaultValue, setDefaultValue] = useState<Place | null>(null);

  function handleSelect(place: Place | null) {
    setDefaultValue(place);
    if (place) onSelect(place);

    setTimeout(() => {
      setDefaultValue(null);
      document.getElementById(id)?.blur();
    }, 100);
  }

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Autocomplete
          disableFloatingLabel
          center={map?.getCenter()}
          defaultValue={defaultValue}
          id={id}
          inputProps={{ color: 'primary' }}
          onSelect={handleSelect}
        />
      </Wrapper>
    </ThemeProvider>
  );
}

const Wrapper = styled.div`
  padding-left: 0;
  width: 300px;

  @media (min-width: 1000px) {
    padding-left: 24px;
  }

  && {
    .MuiFormControl-root {
      background-color: white;
      border-radius: 4px;
      margin: 0;

      input {
        padding: 0;
      }
    }
  }
`;

export default SearchControl;
