import {
  CyclabilityZone,
  CyclabilityZoneService,
  ParkingService,
  Place,
  PoiService,
  TPoiCategoryCode,
  useCancellablePromise,
  useCyclabilityZones,
  useFacilities,
  useGeoveloMap,
  useParkingLots,
  usePois,
  useReports,
  useRouteReports,
  useUnits,
} from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import {
  ReactNode,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { LegendControl } from '../../components/legend-control';
import SearchControl from '../../components/search-control';

import { LngLatBounds } from '!maplibre-gl';

const mapId = 'map';

const usefulCategories: TPoiCategoryCode[] = [
  'useful',
  'bicycleRental',
  'bicyclePump',
  'bicycleHome',
  'drinkingWater',
  'toilets',
  'picnic',
  'tourismOffice',
  'chargingStation',
];

export type TMapRef = { resize: () => void };

function Map({ children, ...props }: { children: ReactNode }, ref: Ref<TMapRef>): JSX.Element {
  const [fitBoundsPrevented, preventFitBounds] = useState(false);
  const {
    zone: { map: zoneMap, codeMap: zoneCodeMap, childrenMap, current: currentZone },
    stats: { selectedKey: selectedPeriodKey },
    pois: { categories: poiCategories },
    report: { reports, selectedReport, types },
    routeReport: { reports: routeReports, selectedReport: selectedRouteReport },
    routing: { currentTab },
    actions: { setMap, setZoneMap, setZoneCodeMap },
  } = useContext(AppContext);
  const searchControlRef = useRef(new SearchControl('search-control'));
  const legendControlRef = useRef(new LegendControl('legend-control'));
  const navigate = useNavigate();
  const { toDistance } = useUnits();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { map, initialized: mapInitialized, bounds, zoom, init: initMap } = useGeoveloMap();
  const { initialized: facilitiesInitialized, init: initFacilities } = useFacilities(map);
  const {
    initialized: cyclabilityZonesInitialized,
    init: initCyclabilityZones,
    update: updateCyclabilityZones,
    clear: clearCyclabilityZones,
  } = useCyclabilityZones(
    map,
    zoneMap,
    {
      onClick: handleCyclabilityZoneClick,
      getTooltipContent: (properties) => {
        return `<h3>${properties?.name}</h3><span>${toDistance(
          (properties?.distance || 0) * 1000,
        )}</span>`;
      },
    },
    {},
  );
  const {
    selectedParking,
    initialized: parkingLotsInitialized,
    init: initParkingLots,
    update: updateParkingLots,
    unselect: unselectParkingLot,
  } = useParkingLots(map, theme);
  const {
    initialized: reportsInitialized,
    init: initReports,
    update: updateReports,
    select: selectReport,
    clear: clearReports,
  } = useReports(map, theme, types, {
    onDetailsClick: (id) => {
      navigate(
        currentZone?.administrativeLevel === 'world'
          ? `/contributions/${id}`
          : currentZone?.administrativeLevel === 'country'
            ? `/${currentZone.countryCode}/contributions/${id}`
            : `/${currentZone?.countryCode}/${currentZone?.code}/contributions/${id}`,
      );
    },
  });
  const {
    initialized: routeReportsInitialized,
    init: initRouteReports,
    update: updateRouteReports,
    select: selectRouteReport,
    clear: clearRouteReports,
  } = useRouteReports(map, { onClick: handleRouteReportClick });
  const {
    selectedPoi,
    initialized: poisInitialized,
    init: initPois,
    update: updatePois,
    unselect: unselectPoi,
  } = usePois(
    map,
    theme,
    false,
    poiCategories,
    usefulCategories.reduce<{ [key in TPoiCategoryCode]?: boolean }>((res, key) => {
      res[key] = true;

      return res;
    }, {}),
    undefined,
    { enqueueSnackbar, closeSnackbar },
  );
  const {
    cancellablePromise: cancellableParkingLotsPromise,
    cancelPromises: cancelParkingLotsPromise,
  } = useCancellablePromise();
  const { cancellablePromise: cancellablePoisPromise, cancelPromises: cancelPoisPromise } =
    useCancellablePromise();

  useEffect(() => {
    if (!mapInitialized) {
      initMap({
        container: mapId,
        customZoomControls: true,
        scaleControl: true,
        minZoom: 2,
      });
    }
  }, []);

  useEffect(() => {
    if (map) setMap(map);
  }, [map]);

  useEffect(() => {
    if (!mapInitialized) return;

    if (map && !map.hasControl(searchControlRef.current)) {
      map.addControl(searchControlRef.current, 'top-left');

      searchControlRef.current.init(theme, { onSelect: handlePlaceSelected });
    }

    if (map && !map.hasControl(legendControlRef.current)) {
      map.addControl(legendControlRef.current, 'bottom-left');

      legendControlRef.current.init(theme);
    }

    if (!facilitiesInitialized) initFacilities(true);
    if (!cyclabilityZonesInitialized) initCyclabilityZones();
    if (!parkingLotsInitialized) initParkingLots();
    if (!poisInitialized) initPois();
    if (!reportsInitialized) initReports();
    if (!routeReportsInitialized) initRouteReports();
  }, [mapInitialized]);

  useEffect(() => {
    if (parkingLotsInitialized) getParkingLots();
  }, [parkingLotsInitialized, bounds, currentTab]);

  useEffect(() => {
    if (poiCategories && poisInitialized) getPois();
  }, [poiCategories, poisInitialized, bounds, currentTab]);

  useEffect(() => {
    if (selectedParking) unselectPoi();
  }, [selectedParking]);

  useEffect(() => {
    if (selectedPoi) unselectParkingLot();
  }, [selectedPoi]);

  useEffect(() => {
    if (reportsInitialized) {
      if (currentTab === 'contributions') {
        if (selectedReport) selectReport(selectedReport);
        else if (reports) updateReports(reports);
        else clearReports();
      } else {
        clearReports();
      }
    }
  }, [reportsInitialized, selectedReport, reports, currentTab]);

  useEffect(() => {
    if (reportsInitialized && currentTab === 'contributions' && !selectedReport && reports)
      updateReports(reports);
  }, [zoom]);

  useEffect(() => {
    if (routeReportsInitialized) {
      if (currentTab === 'route-issues') {
        if (selectedRouteReport) selectRouteReport(selectedRouteReport);
        else if (routeReports) updateRouteReports(routeReports);
        else clearRouteReports();
      } else {
        clearRouteReports();
      }
    }
  }, [routeReportsInitialized, selectedRouteReport, routeReports, currentTab]);

  useEffect(() => {
    if (mapInitialized && currentZone) {
      const {
        bounds: { north, east, south, west },
      } = currentZone;

      if (!fitBoundsPrevented) {
        map?.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }), {
          padding: 50,
        });
      }

      preventFitBounds(false);
    }
  }, [mapInitialized, currentZone]);

  useEffect(() => {
    if (!mapInitialized || !currentZone) return;

    if (currentTab === 'stats') {
      if (
        cyclabilityZonesInitialized &&
        childrenMap[currentZone.id] !== undefined &&
        selectedPeriodKey
      ) {
        const childrenIds = childrenMap[currentZone.id];

        updateCyclabilityZones(
          currentZone,
          childrenIds ? childrenIds.map((id) => zoneMap[id]).filter(Boolean) : null,
          selectedPeriodKey,
        );
      }
    }

    return () => {
      if (cyclabilityZonesInitialized) clearCyclabilityZones();
    };
  }, [
    mapInitialized,
    cyclabilityZonesInitialized,
    currentTab,
    currentZone,
    childrenMap,
    selectedPeriodKey,
  ]);

  useImperativeHandle(ref, () => ({
    resize: () => map?.resize(),
  }));

  async function getParkingLots() {
    cancelParkingLotsPromise();

    if (!map || !bounds || currentTab !== 'stats') {
      updateParkingLots([]);
      return;
    }

    try {
      const parkingLots = await cancellableParkingLotsPromise(
        ParkingService.getParkingLots({ zoom: map.getZoom(), bounds }),
      );

      updateParkingLots(parkingLots);
    } catch (err) {
      console.error(err);
    }
  }

  async function getPois() {
    cancelPoisPromise();

    if (!poiCategories || !map || !bounds || currentTab !== 'stats') {
      updatePois([]);
      return;
    }

    try {
      const pois = await cancellablePoisPromise(
        PoiService.getPois(poiCategories, {
          zoom: map.getZoom(),
          bounds,
          selectedCategories: usefulCategories,
        }),
      );

      updatePois(pois);
    } catch (err) {
      console.error(err);
    }
  }

  function handleCyclabilityZoneClick(zone: CyclabilityZone | null) {
    if (zone) {
      const { administrativeLevel, countryCode, code } = zone;

      navigate(administrativeLevel === 'country' ? `/${countryCode}` : `/${countryCode}/${code}`);
    }
  }

  async function handlePlaceSelected(place: Place) {
    try {
      const {
        zones: [zone],
      } = await CyclabilityZoneService.getZones({
        administrativeLevel: 'CITY',
        point: place.point,
      });

      if (zone && zone.id !== currentZone?.id) {
        if (!zoneMap[zone.id]) setZoneMap({ ...zoneMap, [zone.id]: zone });
        if (!zoneCodeMap[zone.code]) setZoneCodeMap({ ...zoneCodeMap, [zone.code]: zone.id });

        preventFitBounds(true);
        navigate(`/${zone.countryCode}/${zone.code}`);
      }
    } catch (err) {
      //
    }
  }

  function handleRouteReportClick(id: number) {
    if (!currentZone) return;

    navigate(
      currentZone.administrativeLevel === 'world'
        ? `/route-issues/${id}`
        : currentZone.administrativeLevel === 'country'
          ? `/${currentZone.countryCode}/route-issues/${id}`
          : `/${currentZone.countryCode}/${currentZone.code}/route-issues/${id}`,
    );
  }

  return (
    <div {...props}>
      <StyledMap id={mapId} />
      {children}
    </div>
  );
}

const StyledMap = styled.div`
  height: 100%;
  width: 100%;
`;

export default forwardRef(Map);
