import {
  KeyboardArrowDown as ArrowDownIcon,
  CheckCircle as CheckIcon,
  SpeakerNotes as DescriptionIcon,
  Error as ErrorIcon,
  Photo as PhotoIcon,
  Today as TodayIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import EmptyState from '../../components/empty-state';
import ListItemSkeleton from '../../components/list-item-skeleton';
import PeriodDialog from '../../components/period-dialog';
import { getPeriodTitle } from '../../utils/period';

interface IProps {
  selectedTypes: { [key: number]: boolean };
  selectedTypesCount: number;
  selectedTypeChanged: (id: number, checked: boolean) => void;
}

function ReportsList({
  selectedTypes,
  selectedTypesCount,
  selectedTypeChanged,
  ...props
}: IProps): JSX.Element {
  const {
    zone: { current: currentZone },
    report: { reports, types, selectedPeriod },
  } = useContext(AppContext);
  const [periodDialogOpen, openPeriodDialog] = useState(false);
  const { t } = useTranslation();
  const [categoriesMenuAnchorEl, setCategoriesMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  return (
    <>
      <FiltersWrapper>
        <Button
          disableElevation
          endIcon={<TodayIcon />}
          onClick={() => openPeriodDialog(true)}
          style={{ textTransform: 'none' }}
          variant="contained"
        >
          <Trans i18nKey={getPeriodTitle(selectedPeriod)} />
        </Button>
        <Button
          disableElevation
          onClick={({ currentTarget }) => setCategoriesMenuAnchorEl(currentTarget)}
          style={{ textTransform: 'none' }}
          variant="contained"
        >
          <Trans
            count={selectedTypesCount}
            i18nKey="bicycle_facilities.reports.categories"
            values={{ count: selectedTypesCount }}
          />
          <ArrowDownIcon />
        </Button>
        <Menu
          keepMounted
          anchorEl={categoriesMenuAnchorEl}
          onClose={() => setCategoriesMenuAnchorEl(null)}
          open={Boolean(categoriesMenuAnchorEl)}
        >
          <FormControl component="fieldset">
            <FormGroup>
              {types?.map(({ id, titleKey }) => (
                <MenuItem dense key={id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedTypes[id] || false}
                        onChange={(event, checked) => selectedTypeChanged(id, checked)}
                        size="small"
                      />
                    }
                    label={<Trans i18nKey={titleKey} />}
                  />
                </MenuItem>
              ))}
            </FormGroup>
          </FormControl>
        </Menu>
      </FiltersWrapper>
      <List {...props} component="nav">
        {types && currentZone && reports ? (
          reports.length > 0 ? (
            reports.map(({ id, created, creator, description, photoUrl, typeCode, status }) => (
              <ListItemButton
                component={Link}
                key={id}
                to={
                  currentZone.administrativeLevel === 'world'
                    ? `/contributions/${id}`
                    : currentZone.administrativeLevel === 'country'
                      ? `/${currentZone.countryCode}/contributions/${id}`
                      : `/${currentZone.countryCode}/${currentZone.code}/contributions/${id}`
                }
              >
                {status === 'OPEN' ? (
                  <ListItemIcon>
                    <ErrorIcon color="error" />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <>
                      <Trans
                        i18nKey={
                          types?.find((value) => value.code === typeCode)?.titleKey ||
                          'commons.report.default_title'
                        }
                      />
                      <>&nbsp;#{id}</>
                    </>
                  }
                  secondary={t('bicycle_facilities.reports.list.report_subtitle', {
                    creator,
                    created: created.format('LL'),
                  })}
                />
                <ListItemSecondaryAction>
                  <Box display="flex" gap="4px">
                    {!!photoUrl && (
                      <Tooltip
                        title={<Trans i18nKey="bicycle_facilities.reports.list.has_photo" />}
                      >
                        <PhotoIcon />
                      </Tooltip>
                    )}
                    {!!description && (
                      <Tooltip
                        title={<Trans i18nKey="bicycle_facilities.reports.list.has_description" />}
                      >
                        <DescriptionIcon />
                      </Tooltip>
                    )}
                  </Box>
                </ListItemSecondaryAction>
              </ListItemButton>
            ))
          ) : (
            <EmptyState
              description={<Trans i18nKey="bicycle_facilities.reports.list.empty_state" />}
            />
          )
        ) : (
          <ListItemSkeleton />
        )}
      </List>
      <PeriodDialog onClose={() => openPeriodDialog(false)} open={periodDialogOpen} />
    </>
  );
}

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 16px 16px 0;
`;

export default ReportsList;
