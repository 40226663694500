import { GuestRoute, PrivateRoute } from '@geovelo-frontends/commons';
import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Layout from '../../layout/default';
import { AppContext } from '../context';

import routes, { defaultGuestPath, defaultPrivatePath } from './routes';

function Router(): JSX.Element {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map(({ isPrivate, isGuest, path, element, ...props }) => {
            if (isPrivate)
              return (
                <Route
                  element={
                    <PrivateRoute currentUser={currentUser} defaultGuestPath={defaultGuestPath}>
                      {element}
                    </PrivateRoute>
                  }
                  key={path}
                  path={path}
                  {...props}
                />
              );
            if (isGuest)
              return (
                <Route
                  element={
                    <GuestRoute currentUser={currentUser} defaultPrivatePath={defaultPrivatePath}>
                      {element}
                    </GuestRoute>
                  }
                  key={path}
                  path={path}
                  {...props}
                />
              );

            return <Route element={element} key={path} path={path} {...props} />;
          })}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default Router;
