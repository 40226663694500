import { getCyclabilityZonesPeriods } from '@geovelo-frontends/commons';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../app/context';

import ChildrenDialog from './children-dialog';
import Grid from './grid';
import Header from './header';
import Leaderboard from './leaderboard';
import OSMCard from './osm-card';
import OSMDialog from './osm-dialog';

export function Stats(): JSX.Element {
  const [osmDialogOpen, openOSMDialog] = useState(false);
  const [childrenDialogOpen, openChildrenDialog] = useState(false);
  const {
    zone: { map: zoneMap, childrenMap, current: currentZone },
    stats: { periods, selectedKey, selectedIndex },
    actions: { setStatsPeriods, selectStatsKey, selectStatsIndex },
  } = useContext(AppContext);

  useEffect(() => {
    if (currentZone && childrenMap[currentZone.id] !== undefined) {
      const zones = [
        currentZone,
        ...(childrenMap[currentZone.id] || []).map((id) => zoneMap[id]),
      ].filter(Boolean);
      const periods = getCyclabilityZonesPeriods(zones);

      setStatsPeriods(periods);

      const index = periods.findIndex(({ key }) => key === selectedKey);
      if (index === -1) selectStatsIndex(periods.length > 0 ? periods.length - 1 : null);
      else selectStatsIndex(index);
    } else if (currentZone === null) {
      setStatsPeriods(undefined);
      selectStatsKey(null);
      selectStatsIndex(null);
    }
  }, [childrenMap, currentZone]);

  useEffect(() => {
    if (selectedIndex !== null) {
      selectStatsKey(periods?.[selectedIndex]?.key || null);
    }
  }, [selectedIndex]);

  return (
    <>
      <Header openChildrenDialog={openChildrenDialog} />
      <Grid />
      <OSMCard openOSMDialog={openOSMDialog} />
      <Leaderboard />
      <OSMDialog onClose={() => openOSMDialog(false)} open={osmDialogOpen} />
      <ChildrenDialog onClose={() => openChildrenDialog(false)} open={childrenDialogOpen} />
    </>
  );
}
