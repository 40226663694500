import { ReportService, ReportType, RouteReportService, TStatus } from '@geovelo-frontends/commons';
import {
  CheckCircle as CheckIcon,
  ChevronLeft,
  ChevronRight,
  Error as ErrorIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Skeleton, Toolbar, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';

function Header(): JSX.Element {
  const [title, setTitle] = useState<string>();
  const [parentRoute, setParentRoute] = useState<string | null | undefined>();
  const {
    user: { current: currentUser },
    zone: { map: zoneMap, parentMap, current: currentZone },
    report: { reports, allTypes: allReportTypes, selectedReport },
    routeReport: { reports: routeReports, selectedReport: selectedRouteReport },
    actions: {
      setReports,
      setSelectedReport,
      setContributionId,
      setRouteReports,
      setSelectedRouteReport,
    },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [typesMenuAnchorEl, setTypesMenuAnchorEl] = useState<HTMLLIElement | null>(null);

  useEffect(() => {
    if (currentZone) {
      const { administrativeLevel, name } = currentZone;

      setTitle(
        administrativeLevel === 'world'
          ? t('bicycle_facilities.administrative_levels.world') || ''
          : name,
      );
    } else {
      setTitle(undefined);
    }
  }, [currentZone]);

  useEffect(() => {
    if (currentZone && selectedReport !== undefined && selectedRouteReport !== undefined) {
      if (selectedReport || selectedRouteReport) {
        const { administrativeLevel, countryCode, code } = currentZone;
        let _parentRoute = '';

        if (administrativeLevel === 'world') _parentRoute = '';
        else if (administrativeLevel === 'country') _parentRoute = `/${countryCode}`;
        else _parentRoute = `/${countryCode}/${code}`;

        if (selectedReport) _parentRoute += `/contributions`;
        else if (selectedRouteReport) _parentRoute += `/route-issues`;

        setParentRoute(_parentRoute);
      } else {
        const parentId = parentMap[currentZone.id];
        if (parentId === null) {
          setParentRoute(null);
        } else if (parentId !== undefined) {
          const parent = zoneMap[parentId];
          if (parent) {
            const { administrativeLevel, countryCode, code } = parent;

            if (administrativeLevel === 'world') setParentRoute(`/`);
            else if (administrativeLevel === 'country') setParentRoute(`/${countryCode}`);
            else setParentRoute(`/${countryCode}/${code}`);
          } else {
            setParentRoute(null);
          }
        }
      }
    } else {
      setParentRoute(undefined);
    }
  }, [currentZone, parentMap, selectedReport, selectedRouteReport]);

  useEffect(() => {
    if (selectedReport) {
      setTitle(
        `${t(
          allReportTypes?.find((value) => value.code === selectedReport.typeCode)?.titleKey ||
            'commons.report.default_title',
        )} #${selectedReport.id}`,
      );
    } else if (selectedRouteReport) {
      setTitle(
        t('bicycle_facilities.route_reports.list.route_report_title', {
          id: selectedRouteReport.id,
        }) || '',
      );
    } else if (currentZone && selectedReport === null && selectedRouteReport === null) {
      const { administrativeLevel, name } = currentZone;

      setTitle(
        administrativeLevel === 'world'
          ? t('bicycle_facilities.administrative_levels.world') || ''
          : name,
      );
    } else {
      setTitle(undefined);
    }
  }, [currentZone, selectedReport, selectedRouteReport]);

  function seeOnOSM(): void {
    if (selectedReport?.osmNoteId) {
      window.open(`${environment.osm.apiUrl}/note/${selectedReport.osmNoteId}`, '_blank');
      setActionsMenuAnchorEl(null);
    }
  }

  function seeOnJOSM(): void {
    window.open(
      'http://127.0.0.1:8111/load_and_zoom?' +
        (selectedReport?.JOSMParams || selectedRouteReport?.JOSMParams),
      '_blank',
    );
    setActionsMenuAnchorEl(null);
  }

  function seeOnGeovelo(): void {
    window.open(
      selectedRouteReport
        ? `${environment.frontendUrl}/route/${selectedRouteReport.computedRouteId}`
        : selectedReport
          ? `${environment.frontendUrl}/reports/${selectedReport.id}`
          : '',
      '_blank',
    );
    setActionsMenuAnchorEl(null);
  }

  function seeItinerary(): void {
    if (selectedReport?.computedRouteId) {
      window.open(`${environment.frontendUrl}/route/${selectedReport.computedRouteId}`, '_blank');
      setActionsMenuAnchorEl(null);
    }
  }

  async function deleteReport(): Promise<void> {
    if (selectedReport) {
      try {
        await ReportService.deleteReport(selectedReport.id);
        reports?.splice(
          reports?.findIndex(({ id }) => id === selectedReport.id),
          1,
        );
        setReports([...(reports || [])]);
        setContributionId(null);
        if (parentRoute) navigate(parentRoute);
      } catch {
        enqueueSnackbar(t('commons.report.not_deleted'));
      }
      setActionsMenuAnchorEl(null);
    }
  }

  async function updateReport(data: { status?: TStatus; type?: ReportType }): Promise<void> {
    if (selectedReport) {
      try {
        const updatedReport = await ReportService.updateReport(selectedReport.id, data);
        reports?.splice(
          reports?.findIndex(({ id }) => id === selectedReport.id),
          1,
          updatedReport,
        );
        setReports([...(reports || [])]);
        setSelectedReport(updatedReport);
      } catch {
        enqueueSnackbar(t('commons.report.not_updated'));
      }
      setActionsMenuAnchorEl(null);
      setTypesMenuAnchorEl(null);
    } else if (selectedRouteReport) {
      try {
        const updatedRouteReport = await RouteReportService.updateRouteReport(
          selectedRouteReport.id,
          { status: data.status },
        );
        routeReports?.splice(
          routeReports?.findIndex(({ id }) => id === selectedRouteReport.id),
          1,
          updatedRouteReport,
        );
        setRouteReports([...(routeReports || [])]);
        setSelectedRouteReport(updatedRouteReport);
      } catch {
        enqueueSnackbar(t('bicycle_facilities.reports.not_updated'));
      }
      setActionsMenuAnchorEl(null);
      setTypesMenuAnchorEl(null);
    }
  }

  const report = selectedReport || selectedRouteReport;

  return (
    <StyledToolbar>
      {parentRoute === undefined ? (
        <Skeleton height={30} variant="circular" width={30} />
      ) : (
        parentRoute && (
          <IconButton
            component={Link}
            onClick={() => setContributionId(null)}
            size="small"
            to={parentRoute}
          >
            <ChevronLeft />
          </IconButton>
        )
      )}
      <Typography noWrap component="h2" variant="h6">
        {title || <Skeleton variant="text" width={200} />}
      </Typography>
      <StyledSpacer />
      {report && (
        <>
          {report.status === 'OPEN' ? <ErrorIcon color="error" /> : <CheckIcon color="primary" />}
          {currentUser && (
            <>
              <IconButton
                onClick={({ currentTarget }) => setActionsMenuAnchorEl(currentTarget)}
                size="medium"
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                keepMounted
                anchorEl={actionsMenuAnchorEl}
                onClose={() => setActionsMenuAnchorEl(null)}
                open={Boolean(actionsMenuAnchorEl)}
              >
                <MenuItem
                  key="change_state"
                  onClick={() =>
                    updateReport({ status: report.status === 'OPEN' ? 'CLOSED' : 'OPEN' })
                  }
                >
                  <Typography>
                    <Trans
                      i18nKey={
                        report.status === 'OPEN'
                          ? 'commons.report.actions.to_solved'
                          : 'commons.report.actions.to_open'
                      }
                    />
                  </Typography>
                </MenuItem>
                {selectedReport && (
                  <div>
                    <MenuItem
                      key="change_type"
                      onClick={({ currentTarget }) => setTypesMenuAnchorEl(currentTarget)}
                    >
                      <Typography>
                        <Trans i18nKey="commons.report.actions.change_type" />
                      </Typography>
                      <StyledSpacer />
                      <ChevronRight />
                    </MenuItem>

                    <Menu
                      keepMounted
                      anchorEl={typesMenuAnchorEl}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClose={() => setTypesMenuAnchorEl(null)}
                      open={Boolean(typesMenuAnchorEl)}
                    >
                      {allReportTypes?.map((type) => (
                        <MenuItem key={type.id} onClick={() => updateReport({ type })}>
                          <Typography>
                            <Trans i18nKey={type.titleKey} />
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>

                    {selectedReport.osmNoteId && (
                      <MenuItem key="see_on_osm" onClick={() => seeOnOSM()}>
                        <Typography>
                          <Trans i18nKey="commons.report.actions.see_on_osm" />
                        </Typography>
                      </MenuItem>
                    )}
                    <MenuItem key="see_on_josm" onClick={() => seeOnJOSM()}>
                      <Typography>
                        <Trans i18nKey="commons.report.actions.see_on_josm" />
                      </Typography>
                    </MenuItem>
                    <MenuItem key="see_on_geovelo" onClick={() => seeOnGeovelo()}>
                      <Typography>
                        <Trans i18nKey="commons.report.actions.see_on_geovelo" />
                      </Typography>
                    </MenuItem>
                    {selectedReport.computedRouteId && (
                      <MenuItem key="see_itinerary" onClick={() => seeItinerary()}>
                        <Typography>
                          <Trans i18nKey="commons.report.actions.see_itinerary" />
                        </Typography>
                      </MenuItem>
                    )}
                    <MenuItem key="delete" onClick={() => deleteReport()}>
                      <Typography>
                        <Trans i18nKey="commons.actions.remove" />
                      </Typography>
                    </MenuItem>
                  </div>
                )}
                {selectedRouteReport && (
                  <div>
                    <MenuItem key="see_on_josm" onClick={() => seeOnJOSM()}>
                      <Typography>
                        <Trans i18nKey="commons.report.actions.see_on_josm" />
                      </Typography>
                    </MenuItem>
                    <MenuItem key="see_on_geovelo" onClick={() => seeOnGeovelo()}>
                      <Typography>
                        <Trans i18nKey="commons.report.actions.see_on_geovelo" />
                      </Typography>
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </>
          )}
        </>
      )}
    </StyledToolbar>
  );
}

const StyledToolbar = styled(Toolbar)`
  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export default Header;
