import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, IconButton, Skeleton, Toolbar, Typography } from '@mui/material';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';

interface IProps {
  openChildrenDialog: (open: boolean) => void;
}

function Header({ openChildrenDialog }: IProps): JSX.Element {
  const {
    zone: { childrenMap, current: currentZone },
    stats: { periods, selectedIndex },
    actions: { selectStatsIndex },
  } = useContext(AppContext);

  function handlePrev() {
    if (periods && selectedIndex !== null && periods[selectedIndex - 1])
      selectStatsIndex(selectedIndex - 1);
  }

  function handleNext() {
    if (periods && selectedIndex !== null && periods[selectedIndex + 1])
      selectStatsIndex(selectedIndex + 1);
  }

  const period = (periods && selectedIndex !== null && periods[selectedIndex]) || null;

  return (
    <StyledToolbar>
      <IconButton disabled={!period || selectedIndex === 0} onClick={handlePrev} size="small">
        <ChevronLeft />
      </IconButton>
      <PeriodWrapper>
        {period ? (
          <Typography>{period.date.format('MMMM YYYY')}</Typography>
        ) : (
          <Skeleton variant="text" width={100} />
        )}
      </PeriodWrapper>
      <IconButton
        disabled={!period || (periods && selectedIndex !== null && !periods[selectedIndex + 1])}
        onClick={handleNext}
        size="small"
      >
        <ChevronRight />
      </IconButton>
      <StyledSpacer />
      {currentZone && childrenMap[currentZone.id] && (
        <Button
          color="primary"
          onClick={() => openChildrenDialog(true)}
          size="small"
          variant="outlined"
        >
          <Trans i18nKey="bicycle_facilities.stats.actions.see_table" />
        </Button>
      )}
    </StyledToolbar>
  );
}

const StyledToolbar = styled(Toolbar)`
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const PeriodWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 120px;
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export default Header;
