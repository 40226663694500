import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { environment } from '../../environment';

interface IProps {
  openOSMDialog: (open: boolean) => void;
}

function OSMCard({ openOSMDialog }: IProps): JSX.Element {
  return (
    <Wrapper>
      <StyledCard variant="outlined">
        <CardContent>
          <Typography component="p" variant="caption">
            <Trans
              components={[
                <Typography
                  color="primary"
                  component="a"
                  href="https://www.openstreetmap.org"
                  key={0}
                  rel="noreferrer"
                  target="_blank"
                  variant="caption"
                />,
              ]}
              i18nKey="bicycle_facilities.stats.facilities.osm"
            />
          </Typography>
          {environment.enableTmpFacilities && (
            <TmpWrapper>
              <Typography variant="caption">
                <Trans i18nKey="bicycle_facilities.stats.facilities.osm_tmp" />
              </Typography>
            </TmpWrapper>
          )}
        </CardContent>
        <StyledCardActions>
          <Button onClick={() => openOSMDialog(true)} size="small" variant="outlined">
            <Trans i18nKey="commons.actions.more_informations" />
          </Button>
          <Button
            color="primary"
            component="a"
            href="https://www.openstreetmap.org/edit"
            rel="noreferrer"
            size="small"
            target="_blank"
            variant="outlined"
          >
            <Trans i18nKey="commons.actions.contribute" />
          </Button>
        </StyledCardActions>
      </StyledCard>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledCard = styled(Card)`
  && {
    background-color: #eee;
  }
`;

const TmpWrapper = styled.div`
  border: 2px solid ${orange[300]};
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;
`;

const StyledCardActions = styled(CardActions)`
  justify-content: flex-end;
  gap: 8px;
`;

export default OSMCard;
