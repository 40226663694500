import { Tabs } from '@mui/material';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { TTab } from '../../app/router/tabs';

import LinkTab from './link-tab';

interface IProps {
  selectedTab: TTab;
  selectTab: (tab: TTab) => void;
}

function TabBar({ selectedTab, selectTab }: IProps): JSX.Element {
  const {
    user: { current: currentUser },
    zone: { current: currentZone },
  } = useContext(AppContext);

  let route = '';
  if (currentZone?.administrativeLevel === 'world') route = `/`;
  else if (currentZone?.administrativeLevel === 'country') route = `/${currentZone.countryCode}/`;
  else if (currentZone) route = `/${currentZone.countryCode}/${currentZone.code}/`;

  return (
    <Tabs
      indicatorColor="primary"
      onChange={(_, value) => selectTab(value)}
      value={
        selectedTab === 'route-issues' &&
        currentUser !== undefined &&
        (currentUser === null || !currentUser.isAdmin)
          ? 'stats'
          : selectedTab
      }
      variant="fullWidth"
    >
      <StyledLinkTab
        label={<Trans i18nKey="bicycle_facilities.navigation.stats" />}
        to={`${route}stats`}
        value="stats"
      />
      <StyledLinkTab
        label={<Trans i18nKey="bicycle_facilities.navigation.contributions" />}
        to={`${route}contributions`}
        value="contributions"
      />
      {(currentUser === undefined || currentUser?.isAdmin) && (
        <StyledLinkTab
          label={<Trans i18nKey="bicycle_facilities.navigation.route_reports" />}
          to={`${route}route-issues`}
          value="route-issues"
        />
      )}
    </Tabs>
  );
}

const StyledLinkTab = styled(LinkTab)`
  && {
    text-transform: none;
    min-width: 72px;
  }
`;

export default TabBar;
