import { CyclabilityZone, useUnits } from '@geovelo-frontends/commons';
import { BarChart } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from '@mui/material';
import { deepOrange, green } from '@mui/material/colors';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { getChildrenTypeKey } from '../../utils/cyclability-zone';

import EvolutionDialog from './evolution-dialog';

function Leaderboard(): JSX.Element {
  const [children, setChildren] = useState<CyclabilityZone[] | null>();
  const [evolutionDialogOpen, toggleEvolutionDialog] = useState(false);
  const {
    user: { current: currentUser },
    zone: { map: zoneMap, childrenMap, current: currentZone },
    stats: { periods, selectedKey, selectedIndex },
  } = useContext(AppContext);
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const [childrenTypeKey, setChildrenTypeKey] = useState<string>(
    t(getChildrenTypeKey(null), { count: 5 }) || '',
  );
  const { toDistance } = useUnits();

  useEffect(() => {
    if (currentZone && childrenMap[currentZone.id] !== undefined && selectedKey !== null) {
      const ids = childrenMap[currentZone.id];
      if (!ids) return setChildren(null);
      else {
        const _children = ids
          .map((id) => zoneMap[id])
          .filter(Boolean)
          .sort(
            (a, b) =>
              (b.stats.find(({ dateAsKey }) => dateAsKey === selectedKey)?.distances.all || 0) -
              (a.stats.find(({ dateAsKey }) => dateAsKey === selectedKey)?.distances.all || 0),
          )
          .slice(0, 5);

        setChildren(_children);
      }
    } else if (currentZone === null || (currentZone && childrenMap[currentZone.id] === null)) {
      setChildren(null);
    } else {
      setChildren(undefined);
    }
  }, [currentZone, childrenMap, selectedKey]);

  useEffect(() => {
    if (currentZone && children) {
      setChildrenTypeKey(
        t(getChildrenTypeKey(currentZone.administrativeLevel), {
          count: children.length,
        }) || '',
      );
    }
  }, [currentZone, children]);

  if (children === null || (children && children.length === 0)) return <></>;

  const prevKey = (periods && selectedIndex !== null && periods[selectedIndex - 1]?.key) || null;

  return (
    <Box display="flex" flexDirection="column" marginTop={2}>
      <List
        dense
        aria-labelledby="leaderboard-subheader"
        subheader={
          <StyledListSubheader disableSticky component="div" id="leaderboard-subheader">
            <Trans
              count={children?.length || 5}
              i18nKey="bicycle_facilities.stats.leaderboard.caption"
              values={{
                count: children?.length || 5,
                type:
                  currentZone?.administrativeLevel !== 'department' || language !== 'fr'
                    ? childrenTypeKey.toLowerCase()
                    : childrenTypeKey,
              }}
            />
          </StyledListSubheader>
        }
      >
        {children
          ? children.map(
              ({ id, administrativeLevel, name, countryCode, code, stats: _stats }, index) => {
                const stats = _stats.find(({ dateAsKey }) => dateAsKey === selectedKey);
                const distance = (stats?.distances.all || 0) * 1000;
                const prevStats =
                  (prevKey && _stats.find(({ dateAsKey }) => dateAsKey === prevKey)) || null;
                const diff = (prevStats && distance - prevStats.distances.all * 1000) || null;

                return (
                  <Fragment key={id}>
                    {index > 0 && <Divider />}
                    <ListItemButton
                      component={Link}
                      to={
                        administrativeLevel === 'country'
                          ? `/${countryCode}`
                          : `/${countryCode}/${code}`
                      }
                    >
                      <ListItemAvatar>
                        <StyledAvatar>{index + 1}</StyledAvatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Typography noWrap>{name}</Typography>}
                        secondary={
                          <>
                            {toDistance(distance, true, 'km')}
                            <StyledDiff
                              className={diff && diff >= 0 ? 'improvement' : ''}
                              variant="caption"
                            >
                              {diff && (
                                <>
                                  &nbsp; ({diff > 0 ? '+' : '-'}
                                  {toDistance(Math.abs(diff), true, 'km')})
                                </>
                              )}
                            </StyledDiff>
                          </>
                        }
                      />
                    </ListItemButton>
                  </Fragment>
                );
              },
            )
          : [1, 2, 3, 4, 5].map((key, index) => (
              <Fragment key={key}>
                {index > 0 && <Divider />}
                <ListItem>
                  <ListItemAvatar>
                    <StyledAvatar>{index + 1}</StyledAvatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Skeleton variant="text" width={300} />}
                    primaryTypographyProps={{ component: 'div' }}
                    secondary={<Skeleton variant="text" width={100} />}
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </ListItem>
              </Fragment>
            ))}
      </List>
      {currentUser?.isGeovelo && (
        <>
          <Box display="flex" justifyContent="center" padding={2}>
            <Button
              color="primary"
              disabled={!currentZone || !childrenMap[currentZone.id]}
              onClick={() => toggleEvolutionDialog(true)}
              startIcon={<BarChart />}
              variant="contained"
            >
              {t('bicycle_facilities.stats.leaderboard.actions.see_evolution')}
            </Button>
          </Box>
          <EvolutionDialog
            fullWidth
            maxWidth="md"
            onClose={() => toggleEvolutionDialog(false)}
            open={evolutionDialogOpen}
          />
        </>
      )}
    </Box>
  );
}

const StyledListSubheader = styled(ListSubheader)<{ component: 'div' }>`
  && {
    align-items: center;
    display: flex;
    height: 48px;
    line-height: inherit;
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.palette.primary.main};
    height: 36px;
    width: 36px;
  }
`;

const StyledDiff = styled(Typography)`
  color: ${deepOrange[500]};

  &.improvement {
    color: ${green[500]};
  }
`;

export default Leaderboard;
