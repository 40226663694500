import { Ratings, ReportService, Review } from '@geovelo-frontends/commons';
import {
  AccountCircle as AccountIcon,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
} from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';

interface IProps {
  openReviewDialog: (open: boolean) => void;
}

function Details({ openReviewDialog }: IProps): JSX.Element {
  const {
    user: { current: currentUser },
    report: { selectedReport },
    actions: { setSelectedReport, openSignInDialog },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function handleRating(rating: Ratings): Promise<void> {
    if (!selectedReport) return;

    if (!currentUser) {
      openSignInDialog(true);
      return;
    }

    let review: Review | null | undefined;
    if (currentUser.username) {
      review =
        rating === Ratings.Useful
          ? selectedReport.getSignedInUserUsefulReview(currentUser.id)
          : selectedReport.getSignedInUserUnusefulReview(currentUser.id);
    }
    if (review) {
      try {
        await ReportService.deleteReview(review.id);

        const index = selectedReport.reviews.findIndex(({ id }) => id === review?.id);
        selectedReport.reviews.splice(index, 1);
        setSelectedReport(selectedReport.clone());
      } catch {
        enqueueSnackbar(t('commons.report.reviews.not_added'));
      }
    } else {
      try {
        const newReview = await ReportService.addReview({
          reportId: selectedReport.id,
          comment: '',
          rating,
        });
        selectedReport.reviews.push(newReview);

        if (currentUser.username) {
          review =
            rating === Ratings.NotUseful
              ? selectedReport.getSignedInUserUsefulReview(currentUser.id)
              : selectedReport.getSignedInUserUnusefulReview(currentUser.id);
        }

        if (review) {
          const index = selectedReport.reviews.findIndex(({ id }) => id === review?.id);
          selectedReport.reviews.splice(index, 1);
        }

        setSelectedReport(selectedReport.clone());
      } catch {
        enqueueSnackbar(t('commons.report.reviews.not_added'));
      }
    }
  }

  function ratingIconColor(isRatingPositive: boolean): 'primary' | 'error' | undefined {
    if (!currentUser || !currentUser.username || !selectedReport) return undefined;

    if (isRatingPositive) {
      const review = selectedReport.getSignedInUserUsefulReview(currentUser.id);
      return review ? 'primary' : undefined;
    } else {
      const review = selectedReport.getSignedInUserUnusefulReview(currentUser.id);
      return review ? 'error' : undefined;
    }
  }

  return (
    <div>
      {selectedReport && (
        <>
          <StyledCard>
            {selectedReport.photoUrl && (
              <StyledPhoto alt="Report photo" src={selectedReport.photoUrl} />
            )}
            <CardContent>
              <Typography variant="body1">{selectedReport.description}</Typography>
              <StyledTypography variant="caption">
                <Trans
                  i18nKey="bicycle_facilities.reports.list.report_subtitle"
                  values={{
                    created: moment(selectedReport.created).format('LLL'),
                    creator: selectedReport.creator || '',
                  }}
                />
              </StyledTypography>
            </CardContent>
            <StyledCardActions>
              <Button
                color={ratingIconColor(true)}
                onClick={() => handleRating(Ratings.Useful)}
                startIcon={<ThumbUpIcon />}
              >
                ({selectedReport.nbUsefulRatings})
              </Button>
              <Button
                color={ratingIconColor(false)}
                onClick={() => handleRating(Ratings.NotUseful)}
                startIcon={<ThumbDownIcon />}
              >
                ({selectedReport.nbNotUsefulRatings})
              </Button>
            </StyledCardActions>
          </StyledCard>
          <StyledReviews>
            <StyledReviewsTitle>
              <Typography color="textSecondary" variant="body1">
                <Trans
                  count={selectedReport.nbReviews}
                  i18nKey="commons.report.reviews.title"
                  values={{ count: selectedReport.nbReviews }}
                />
              </Typography>
              {currentUser && (
                <Button color="primary" onClick={() => openReviewDialog(true)} variant="contained">
                  <Trans i18nKey="commons.actions.comment" />
                </Button>
              )}
            </StyledReviewsTitle>
            {!currentUser && (
              <StyledConnexion>
                <Typography variant="body2">
                  <Trans i18nKey="commons.report.reviews.not_signedin" />
                </Typography>
                <StyledButton
                  color="primary"
                  onClick={() => openSignInDialog(true)}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.sign_in" />
                </StyledButton>
              </StyledConnexion>
            )}
            {selectedReport.reviews
              .filter(({ comment }) => !!comment)
              .map((review) => (
                <StyledReview key={review.id}>
                  {review.creator ? (
                    <Tooltip title={review.creator.username}>
                      <AccountIcon color="secondary" />
                    </Tooltip>
                  ) : (
                    <AccountIcon color="secondary" />
                  )}
                  <Card>
                    <CardContent>
                      <Typography variant="body2">{review.comment}</Typography>
                      <Typography variant="caption">
                        {moment(review.created).format('LLL')}
                      </Typography>
                    </CardContent>
                  </Card>
                </StyledReview>
              ))}
          </StyledReviews>
        </>
      )}
    </div>
  );
}

const StyledCard = styled(Card)`
  margin: 16px;
`;

const StyledPhoto = styled.img`
  width: 100%;
`;

const StyledReviews = styled.div`
  margin: 24px;

  > * {
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;

const StyledReviewsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledReview = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;

  > * {
    &:first-child {
      margin-right: 16px;
    }

    &:last-child {
      flex-grow: 1;
    }
  }
`;

const StyledConnexion = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  && {
    margin-left: 16px;
    min-width: 150px;
  }
`;

const StyledTypography = styled(Typography)`
  display: block;
  text-align: end;
`;

const StyledCardActions = styled(CardActions)`
  justify-content: flex-end;
`;

export default Details;
