import { AuthService } from '@geovelo-frontends/commons';
import { Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';

interface IProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
}

function ProfileMenu({ anchorEl, onClose }: IProps): JSX.Element {
  const {
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  async function handleLogout() {
    try {
      await AuthService.signOut();
      onClose();
      setTimeout(() => setCurrentUser(null), theme.transitions.duration.leavingScreen);
    } catch {
      enqueueSnackbar(t('commons.not_signed_out'));
    }
  }

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      id="profile-menu"
      onClose={() => onClose()}
      open={Boolean(anchorEl)}
    >
      <MenuItem dense onClick={handleLogout}>
        <Trans i18nKey="commons.actions.sign_out" />
      </MenuItem>
    </Menu>
  );
}

export default ProfileMenu;
