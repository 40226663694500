import {
  Period,
  currentMonth,
  currentWeek,
  currentYear,
  last12Months,
  last30Days,
  last7Days,
  periodTypes,
  today,
} from '@geovelo-frontends/commons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../app/context';

const initialCustomDates: { from: Moment; to: Moment } = {
  from: moment().startOf('month'),
  to: moment().endOf('day'),
};

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

function PeriodDialog({ onClose, ...props }: TProps): JSX.Element {
  const {
    report: { selectedPeriod },
    actions: { setSelectedPeriod },
  } = useContext(AppContext);
  const [periodType, setPeriodType] = useState<string>();
  const [customDates, setCustomDates] = useState<{ from: Moment; to: Moment }>(initialCustomDates);

  useEffect(() => {
    if (props.open)
      setCustomDates(selectedPeriod?.type === 'custom' ? selectedPeriod : initialCustomDates);
  }, [props.open]);

  useEffect(() => {
    if (selectedPeriod) setPeriodType(selectedPeriod.type);
  }, [selectedPeriod]);

  function handleSubmit(): void {
    if (selectedPeriod?.type !== periodType) {
      switch (periodType) {
        case 'week':
          setSelectedPeriod(currentWeek);
          break;
        case 'month':
          setSelectedPeriod(currentMonth);
          break;
        case 'year':
          setSelectedPeriod(currentYear);
          break;
        case 'today':
          setSelectedPeriod(today);
          break;
        case '7days':
          setSelectedPeriod(last7Days);
          break;
        case '30days':
          setSelectedPeriod(last30Days);
          break;
        case '12months':
          setSelectedPeriod(last12Months);
          break;
        case 'custom':
          if (customDates.from && customDates.to) {
            setSelectedPeriod(
              new Period('custom', customDates.from.clone(), customDates.to.clone()),
            );
          }
          break;
        default:
          return;
      }
    }
    onClose();
  }

  return (
    <Dialog fullWidth maxWidth="xs" {...props}>
      <DialogTitle>
        <Trans i18nKey="commons.periods.actions.select" />
      </DialogTitle>
      <form>
        <StyledDialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              name="periodType"
              onChange={({ target: { value } }) => setPeriodType(value)}
              value={periodType}
            >
              {periodTypes.map(
                ({ key, titleKey, alternativeTitleKey }) =>
                  key !== 'allTime' && (
                    <FormControlLabel
                      control={<Radio />}
                      key={key}
                      label={
                        <Trans i18nKey={alternativeTitleKey ? alternativeTitleKey : titleKey} />
                      }
                      value={key}
                    />
                  ),
              )}
            </RadioGroup>
          </FormControl>
          <Box display="flex" gap="16px">
            <Box width="calc(50% - 8px)">
              <DatePicker
                disableFuture
                disabled={periodType !== 'custom'}
                maxDate={customDates.to}
                onChange={(from) => {
                  if (from) setCustomDates({ ...customDates, from });
                }}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                value={customDates.from}
              />
            </Box>
            <DatePicker
              disabled={periodType !== 'custom'}
              minDate={customDates.from}
              onChange={(to) => {
                if (to) setCustomDates({ ...customDates, to });
              }}
              slotProps={{ textField: { size: 'small', fullWidth: true } }}
              value={customDates.to}
            />
          </Box>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={() => onClose()} size="small" variant="outlined">
            <Trans i18nKey="commons.actions.cancel" />
          </Button>
          <Button
            color="primary"
            disabled={periodType === 'custom' && (!customDates.from || !customDates.to)}
            onClick={handleSubmit}
            size="small"
            variant="contained"
          >
            <Trans i18nKey="commons.actions.confirm" />
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
}

const StyledDialogActions = styled(DialogActions)`
  && {
    margin: 16px;
    justify-content: space-between;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;

export default PeriodDialog;
